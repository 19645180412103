.displayPlanMain {
    .description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 21px;
        text-align: justify;
        color: var(--primary);

}
}