.header {
    position: fixed;
    top: 0;
    z-index: 1000;
    background: var(--secondary);
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;

    .headerInner {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px;

        .left {
            display: flex;
            align-items: center;

            .title {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 0.875rem;
                padding-left: 10px;
                color: #000000;
            }
        }

        .right {
            display: flex;
            align-items: center;

            .alertOuter {
                position: relative;
                margin-right: 10px;

                .notificationImg {
                    position: absolute;
                    top: -2px;
                    right: -2px;
                    width: 10px;
                }
            }

            .userOuter {
                position: relative;
                text-align: center;

                .img {
                    height: 2rem;
                    width: 2rem;
                    border-radius: 26px;
                }

                .userName {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    color: #fff;
                    top: 4px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 0.875rem;
                    text-transform: uppercase;
                }
            }

        }


    }
}

.sidebar {
    position: fixed;
    z-index: 1002;
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;

    .sidebar-width {
        width: 50%;
    }

    .headerInner {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px;

        .left {
            display: flex;
            align-items: center;

            .title {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 0.875rem;
                padding-left: 10px;
                color: #000000;
            }
        }


        .right {
            display: flex;
            align-items: center;
            margin-left: 1rem;

            .img {
                height: 6rem;
                width: 6rem;
                border-radius: 3rem;
            }

            .alertOuter {
                position: relative;
                margin-right: 10px;

                .notificationImg {
                    position: absolute;
                    top: -2px;
                    right: -2px;
                    width: 10px;
                }
            }

            .userOuter {
                position: relative;
                text-align: center;

                .userName {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    color: #fff;
                    top: 4px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 0.875rem;
                    text-transform: uppercase;
                }
            }
        }
    }

    .profile {
        margin-left: .1rem;

        .name {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 17px;
            color: var(--primary);
        }

        .role {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.37);
            margin: 0px;
        }
    }

    .block {
        height: 100vh;
        justify-content: space-between;
        display: grid;

        .content {
            padding: 10px 1rem;
            margin: 0px 1rem;

            .li-image {
                margin-right: 10px;
                width: 15px;
            }
            svg {
                margin-right: 10px;
                // width: 15px;
            }

            .li-text {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 17px;
                margin-left: 10px;
                color: var(--primary);
            }

            .logo {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 0.9rem;
                line-height: 21px;
                color: var(--primary);
            }

            .version {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                color: rgba(0, 0, 0, 0.37);

            }
            .company-logo-in-sidebar{
                width: 90%;
                height: auto;
            }
        }

        .back-color {
            background: var(--primary);
        }

        .text-color {
            color: #FFFFFF !important;
        }
    }
}

.count {
    position: absolute;
    top: 0rem;
    right: -3px;
    font-size: 15px;
    background: #fff;
    border-radius: 15px;
    height: 19px;
    width: 19px;
    display: flex;
    align-items: center;
    color: var(--primary);
    justify-content: center;
}

.selectLanguageIcon{
    font-size: 1.7rem;
    color: var(--primary);
    margin-right: 0.5rem;
    border: 1px solid var(--primary);
    border-radius: 1rem;
    cursor: pointer;
}


.langSelectPopup{
  width: 6rem;
  position: absolute;
  top: 53px;
  background: #ffffff;
  padding: 0.5rem; 
  right: 14px;
  border: 1px solid rgb(197, 195, 195);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  border-radius: 0.25rem;
  &::before {
    content: " ";
    position: absolute;
    right: 27px;
    top: -17px;
    border-top: none;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-bottom: 35px solid #ffffff;
  }
  button{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 24px;
      text-align: center;
      color: var(--primary);
      text-align: left;
      cursor: pointer;
  }
}

.coin {
  position: relative;
  margin-right: 10px;
  .coinDivOuter {
    display: flex;
    background: #1A4D2E;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 85%;
    color: #FFFFFF;
    border-radius: 20.5px 5px 5px 20.5px;
    align-items: center;
    padding: 5px 10px 5px 5px;
    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  } 

  .coinPopup {
    position: absolute;
    top: 74px;
    background: #FAFAFA;
    border-radius: 10px;
    padding: 28px 28px;
    right: -75px;
    &::before {
      content: " ";
      position: absolute;
      right: 75px;
      top: -35px;
      border-top: none;
      border-right: 20px solid transparent;
      border-left: 20px solid transparent;
      border-bottom: 35px solid #FAFAFA;
    }
    .boxOuter {
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
      .box {
        padding: 15px 10px;
        background: #FFFFFF;
        border-radius: 5px;
        width: 90px;
        text-align: center;
        position: relative;
        .title {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
        .subTitle {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 8px;
          line-height: 9px;
          color: #06283D;
        }
        .text {
          width: max-content;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 9px;
          line-height: 12px;
          text-align: center;
          color: rgba(0, 0, 0, 0.37);
          margin: 0 auto;
        }
        .highlightedText {
          position: absolute;
          right: 6px;
          top: 20px;
          display: flex;
          align-items: baseline;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 3px;
          line-height: 4px;
          text-align: center;
          color: #39C570;
        }
      }
    }

    .availability {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .coinsData {
        .text {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.37);
          .title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            color: #000000;
            margin-right: 7px;
          }
        }
        .desc {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          margin-top: -5px;
        }
      }
      .addMoreBtn {
        background: #1A4D2E;
        border-radius: 5px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #FFFFFF;
        padding: 11px 27px;
      }
    }
    
  }

}

.enrollmentSuccess {
  text-align: center;
  margin: auto;
  align-items: baseline;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  .enrollmentSuccessInner {
    width: 370px;
    .successImg {
      margin: 0 auto;
      margin-bottom: 35px;
    }
    .title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #06283D;
    }
    .subTitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.37);
    }
    .boxOuter {
      background: #FAFAFA;
      border-radius: 10px;
      padding: 30px 38px;
      text-align: right;
      margin-top: 20px;
      margin-bottom: 34px;
      p.text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #06283D;
        &:nth-of-type(1) {
          padding-bottom: 30px;
        }
      }
    }
    .loaderImg {
      margin: 0 auto;
    }
    .loadingText {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.37);
      padding-top: 30px;
    }
  }
}

.coinTable {
  .inline-block {
    padding: 20px 20px;
  }
  .table-bor {
    background: #FAFAFA;
    border-radius: 10px;
    table {
      thead.dark\:bg-neutral-900 {
        background: #1A4D2E;
        tr {
          th {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #FFFFFF;
          }
        }
      }
      tbody {
        tr {
          .whitespace-nowrap {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 15px;
            color: rgba(0, 0, 0, 0.37);
          }
          .whitespace-nowrap.dark {
            font-weight: 600;
            color: #1A4D2E;
          }
          .whitespace-nowrap.dark.total {
            font-size: 0.83rem;
          }
        }
      }
    }
  }
  .pointsBtn {
    background: #1A4D2E;
    border-radius: 10px;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    position: relative;
    padding: 18px 26px;
    .subTitle {
      position: absolute;
      left: 30px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 15px;
      color: #FFFFFF;
      width: 10px;
    }
  }
}

@media (max-width: 768px) {
    .sidebar .sidebar-width {
        width: 75%;
    }
}
