.basicMedicalPage {
  .data {
    .selectAllDependants{
      .css-ahj2mt-MuiTypography-root{
        font-style: normal;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 18px;
        color: var(--primary);
        cursor: pointer;
      }
      
    }
    .linksOuter {
      display: flex;
      justify-content: space-between;

      .addLink {
        font-style: normal;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 18px;
        text-decoration-line: underline;
        color: var(--primary);
        cursor: pointer;
      }
      .viewLink {
        font-style: normal;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 18px;
        text-decoration-line: underline;
        color: var(--primary);
        cursor: pointer;
      }
    }
    .next {
      background: var(--secondary);
      width: 100%;
      padding: 15px 0px;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 24px;
      color: var(--primary);
      border: 1px solid var(--secondary);
    }
    .skip {
      margin-right: 15px;
      color: var(--primary);
      background: #fff;
      border: 1px solid var(--primary);
    }
  }

  .radioButtonOuter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .radioButtonOuter .radioButton {
    display: inline-flex;
    background: var(--tertiary);
    padding: 15px 15px;
    margin: 0.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 15px;
    color: var(--primary);
    align-items: center;
    width: 48%;
    background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
  background-size: 200%;
  transition: .25s ease-out;
  background-position: right;
  }
  .radioButtonOuter .radioButton .radioOuter {
    position: relative;
    margin-right: 5px;
    min-width: 1.7rem;
  }
  .radioButtonOuter .radioOuter .radioCheck {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 7px;
    opacity: 0;
  }

  .radioButtonOuter .radioButton.active .radioCheck {
    opacity: 1;
  }
  .radioButtonOuter .radioOuter svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 4px;
    opacity: 1;
  }

  .radioButtonOuter .radioButton.active {
    background: var(--primary);
    color: #fff;

    background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
  background-size: 200%;
  transition: .25s ease-out;
  background-position: left;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(400px);
      animation-timing-function: ease-out;
    }
    100% {
      transform: translateY(0px);
      animation-timing-function: ease-out;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .totalCostPopup {
    animation: slideIn 1s linear;
    background: #fff;
    bottom: 0px;
    z-index: 1;
    border: 1px solid green;
    border-radius: 33px 33px 0px 0px;
    padding: 15px 15px;
    @media (min-width: 768px) {
      width: 768px;
      border-radius: 33px 33px 33px 33px;
      padding: 2rem;
    }
    
    @media (max-width: 768px) {
      position: fixed;
      width: 100%;
      table{
        width: 100%;
      }
    }
  }
  .totalCostPopup .lineGreen {
    margin: 0 auto;
    padding: 15px 0px;
  }
  .totalCostPopup .title {
    font-weight: 600;
    font-size: 1rem;
    color: var(--primary);
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .totalCostPopup table thead {
    border-top: 0.7px solid rgba(0, 0, 0, 0.23);
    border-bottom: 0.7px solid rgba(0, 0, 0, 0.23);
  }
  .totalCostPopup table thead tr th {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.37);
    text-align: right;
  }
  .totalCostPopup table thead tr th:nth-of-type(1) {
    text-align: left;
  }
  .totalCostPopup table tbody tr td {
    padding: 10px 0;
  }
  .totalCostPopup table tbody tr:nth-last-child(1) {
    border-top: 0.7px solid rgba(0, 0, 0, 0.23);
    border-bottom: 0.7px solid rgba(0, 0, 0, 0.23);
  }
  .totalCostPopup table tbody tr td {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 18px;
    color: var(--primary);
    text-align: right;
  }
  .totalCostPopup table tbody tr td:nth-of-type(1) {
    text-align: left;
  }
  .totalCostPopup table tbody tr:nth-last-child(1) td {
    font-weight: 600;
    font-size: 1rem;
    line-height: 30px;
    color: var(--primary);
  }
  .totalCostPopup .total {
    padding-top: 50px;
  }
  .totalCostPopup .total .totalOuter .totalText {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.37);
  }
  .totalCostPopup .total .totalOuter .include {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.65);
    padding-top: 5px;
    font-style: italic;
  }
  .totalCostPopup .total .totalOuter .include .total {
    font-weight: 600;
    font-size: 1rem;
    line-height: 30px;
    color: var(--primary);
    // padding-right: 10px;
  }
  .totalCostPopup .total .backBtn {
    background: var(--secondary);
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    color: var(--primary);
    text-transform: uppercase;
    width: 50%;
    padding: 10px;
  }

  .enrollValidations{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    // background-color: #ff551a;
    color: red;
    margin: 0.5rem 1rem;
    padding: 0.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 18px;
    align-items: center;
    font-weight: 500;
    svg{
      width: 2rem;
      height: 1.25rem;
    }
  }

  @media (min-width: 768px) {
    .popupWrapper{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #fafafa; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #aeaeae; 
    border-radius: 4px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #929292; 
  }
  
}
