.new-learn-main-container{
    .main-tabs-section {
        margin: 1rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.5rem;     
        
        .learnTab {
            background-color: var(--tertiary);
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 0.9rem;
            line-height: 1rem;
            color: var(--primary);
            padding: 1.25rem 0px;
            // height: 70px;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
        }
        .active {
            background-color: var(--primary);
            color: var(--secondary);
        }
    }
    .tiles-primary-div{
        // width: 100%;
        margin: 0px 1rem;
        .primary-tile{
            // cursor: pointer;
            width: 100%;
            padding: 1rem;
            .desc{
                font-size: 0.75rem;
                font-weight: 500;
                color: gray;
            }
        }
    }
    
    .tiles-main-div {
        margin: 1rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.5rem;
        .tile {
            min-height:12rem;
            background-color: var(--tertiary);
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 1rem;
            color: var(--primary);
            padding: 2.5rem 1rem;
            // height: 70px;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
    
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
    
            @media (max-width:500px){
                padding: 1.5rem 0.25rem;
            }
    
            img {
            width: 5rem;
            height: 5rem;
            mix-blend-mode: multiply;
            }
    
            p {
                margin-top: 0.5rem;
            }
        }
        // .tile-0{
        //     background-color: rgb(250, 243, 227);
        // }
        // .tile-1{
        //     background-color: rgb(255, 230, 230);;
        // }
        // .tile-2{
        //     background-color: rgb(245, 247, 247);;
        // }
        // .tile-3{
        //     background-color: rgb(231, 246, 255);;
        // }
        // .tile-4{
        //     background-color: rgb(214, 222, 233);;
        // }    
        // .tile-5{
        //     background-color: rgb(250, 243, 227);
        // }
        // .tile-6{
        //     background-color: rgb(255, 230, 230);;
        // }
        // .tile-7{
        //     background-color: rgb(245, 247, 247);;
        // }
        // .tile-8{
        //     background-color: rgb(231, 246, 255);;
        // }
        // .tile-9{
        //     background-color: rgb(214, 222, 233);;
        // } 
    }
    .main-tabs-scroll{
        margin-bottom: 2rem;
        .tab-sliders{
            .custom-setting {
                margin: 15px 1rem !important; 
                .slick-slide {
                    height: auto !important;  
                   
                    .sideBarActive .list-menu {
                        background-color: transparent;
                        border: none;
                        font-family: 'Poppins' !important;
                        font-style: normal !important;
                        font-weight: 400 !important;
                        font-size: 0.9rem !important;
                        line-height: 15px !important;
                        text-align: center !important;
                        color: #ffffff ;
                    
                        cursor: pointer;
                    }
                    .sideBarInActive .list-menu {
                        background-color: transparent;
                        border: none;
                        font-family: 'Poppins' !important;
                        font-style: normal !important;
                        font-weight: 400 !important;
                        font-size: 0.9rem !important;
                        line-height: 15px !important;
                        text-align: center !important;
                        color: #1A4D2E ;
                    
                        cursor: pointer;
                    } 
                }
                 .slick-active{
                        // margin: 0px 4px !important;
                        border-left: 2px solid white;
                        border-right: 2px solid white;
                    }
                .slick-prev {
                    left: 20px;
                    z-index: 1;
                }
                .slick-next {
                    right: 15px;
                    z-index: 1;
                }   
                .learnTab {
                     min-height: 3.6rem;
                    // margin: 0px 0.5rem !important;
                    background-color: var(--tertiary);
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 0.9rem;
                    line-height: 1rem;
                    color: var(--primary);
                    padding: 1.25rem 0px;
                    // height: 70px;
                    text-align: center;
                    vertical-align: middle;
                    cursor: pointer;
                }
                .active {
                    background-color: var(--primary);
                    color: var(--secondary);
                }     
           
            } 
        }
    }
    .learn-modal-container{
        // color: var(--primary);
        img{
            width: 5rem;
        }
        .tile-title{
            color: var(--primary) !important;
            font-size: 1rem;
            font-weight: 600;
            margin: 1rem 0px;
        }
        table {
            margin: 1rem 0px;
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            thead{
                background-color: var(--secondary);
            }
            tbody{
                background-color: #f2f2f2;
            }
          }
          
          td, th {
            border: 1px solid gray;
            padding: 0.25rem;
          }
        .content-description{
            font-family: 'Poppins' !important;
            div, p, span, li, a{
                font-family: 'Poppins' !important;
            }
            div, p{
                margin-top: 0.25rem;
                margin-bottom: 0.25rem;
            }
            ul, ol{
                list-style: revert !important;
                margin-left: 1rem !important;
            }
        }
    }
}