.AddDependentPage {
  .container {
    width: 90%;
  }
  .eclipseOuter {
    position: relative;
  }
  .eclipseOuter .count {
    position: absolute;
    left: 0;
    right: 0;
    top: 18px;
    font-size: 0.5rem;
    margin: 0 auto;
    text-align: center;
    font-weight: 400;

    @media (max-width: 768px) {
      top: 15px;
    }
  }

  .details {
    .title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 24px;
      color: var(--primary);
    }
    .subTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.37);
        padding: 15px 0;
    }
  }

  .bottomButtonReq{
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .css-wfrrvk-MuiStack-root {
    width: 90%;
  }
  .css-3x4vfw-MuiStack-root {
    margin: auto;
  }

  .css-9hmqkk-MuiFormControl-root-MuiTextField-root .MuiInputBase-input:focus {
    border: none !important;
  }
}



@media (min-width: 768px) {
  .css-jd8vth-MuiStack-root {
    width: 100% !important;
    padding: 0rem 1rem;
  }
}