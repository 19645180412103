.groupPlanFinalPage{
    .data {
        padding: 30px 1rem;
        .PageTitle-And-Description {
            margin-left: 0;
            margin-top: 0;
        }
        .bottomButtonReq {
            padding: 0;
        }
    }
    .data .btnOuter {
        background: var(--tertiary);
    }
    .data .details .title {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 21px;
        color: var(--primary);
    }
    .data .details .subTitle {
        font-weight: 400;
        font-size: 0.6875rem;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.51);
    }
    .data .card {
        padding: 10px;
        width: 100%;
    }
    .data .card .name .title {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.51);
        padding-bottom: 5px;
    }
    .data .card .name .subTitle {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 21px;
        color: var(--primary);
    }
    .data .card button {
        background: var(--secondary);
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 18px;
        color: var(--primary);
        padding: 5px 7px;
        height: fit-content;
    }
    .data .card .coverage {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.51);
        // padding-top: 15px;
    }
    .data .card .amount {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 21px;
        color: var(--primary);
    }
}

@media (min-width: 768px) {
    .css-1osj8n2-MuiGrid-root {
        flex-basis: 100% !important;
        max-width: 100% !important;
    }
}