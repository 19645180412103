.uploadDetailsReimbursement{

    @media (min-width: 500px) {
        margin: 0px 2rem;
    }

    .mainTitle{
        margin-top: 1rem;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 24px;
        color: var(--primary);
    }

    .formDiv{
        margin-top: 1rem;
        .MuiFormControl-root{
            margin: 0.5rem 0px;
            .css-1pnmrwp-MuiTypography-root{
                background-color: #f3f2ef;
                padding: 7px;
                margin-left: -12px;
            }
        }
        .updoctitle{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.82rem;
            line-height: 18px;

            color: rgba(0, 0, 0, 0.37);
        }
        .raised-button-file{
            .css-1w1rijm-MuiButtonBase-root-MuiButton-root{
                width: 50%;
            height: 7rem;
            border: 1px solid #afaeac;
            border-style: dashed;
            background-color: #f2f2f2;
            }
            
            svg{
                font-size: 5rem;
            }
        }
    }
    .receiptImagesBlock{
         @media(max-width:500px){
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            }
            @media(min-width:500px){
                display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
            }
        
        margin-top: 1rem;
        .receiptImages-outer{
           
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 10rem;
            position: relative;
            border: 1px solid black;

           
        }
        
    }
    .remainingAmountBlock{
        background-color: #f2f2f2;
        color: var(--primary);
        width: 60%;
        margin: 1rem 0px;
        padding: 1rem;
        margin-left: 40%;
        text-align: center;
        border-radius: 0.5rem;
        @media(max-width:600px){
          width: 100%;
          margin-left: 0px;
        }
    }
}