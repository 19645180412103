.carLeaseProgram {
  .mainTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    color: var(--primary);
  }
  .cardOuter {
    .card {
      background: #FAFAFA;
      padding: 30px 0px;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .imageOuter {
        .cardImg {
          margin: 0 auto;
          display: block;
        }
        .cardImg.active {
          display: none;
        }
      }
      .title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 14px;
        color: var(--primary);
        padding-top: 10px;
      }
      .activeImg {
        opacity: 0;
        position: absolute;
        top: 10px;
        right: 11px;
      }
    }
    .card.active {
      background: var(--primary);
      .imageOuter {
        .cardImg {
          display: none;
        }
        .cardImg.active {
          display: block;
        }
      }
      .title {
        color: #fff;
      }
      .activeImg {
        opacity: 1;
      }
    }
  }
  .bannerTextOuter {
    padding: 25px 1rem;
    background: rgba(250, 243, 227, 0.65);
    margin-bottom: 30px;
    .bannerText {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.37);
    }
  }
  .info {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.37);
  }
  .download {
    display: flex;
    align-items: center;
    .files {
      padding-right: 20px;
    }
    .link {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 24px;
      text-decoration-line: underline;
      color: var(--primary);
    }
  }

  .browseOuter {
    border: 0.3px dashed #000000;
    text-align: center;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    .browseInner {
      .browse {
        margin: 0 auto;
      }
    }
  }

  .successs {
    text-align: center;
    .successUpload {
      margin: 0 auto;
      padding: 40px 0px;
    }
    .text1 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: var(--primary);
      padding-bottom: 10px;
    }
    .text2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: var(--primary);
      padding-bottom: 40px;
    }
    .viewCost {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
      color: var(--primary);
      text-transform: uppercase;
    }
  }

  



  .radioButtonOuter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .radioButtonOuter .radioButton {
    display: inline-flex;
    background: var(--tertiary);
    padding: 15px 15px;
    margin: 0.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 15px;
    color: var(--primary);
    align-items: center;
    width: 48%;
    // background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
    background-size: 200%;
    transition: .25s ease-out;
    background-position: right;
  }
  .radioButtonOuter .radioButton .radioOuter {
    position: relative;
    margin-right: 5px;
    min-width: 1.7rem;
  }
  .radioButtonOuter .radioOuter .radioCheck {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 7px;
    opacity: 0;
  }

  .radioButtonOuter .radioButton.active .radioCheck {
    opacity: 1;
  }
  .radioButtonOuter .radioOuter svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 4px;
    opacity: 1;
  }

  .radioButtonOuter .radioButton.active {
    background: var(--primary);
    color: #fff;
    background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
    background-size: 200%;
    transition: .25s ease-out;
    background-position: left;
  }
}