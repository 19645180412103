.learningPageBasics {
    .eligibility-block {
        background: var(--tertiary);
        padding: 0rem 1rem;

        .title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 17px;
            color: var(--primary);
            padding: 1rem 0px 0rem 0px;
        }

        .description {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.37);
            padding: 1rem 0px;
        }
    }

    .custom-setting {
        margin: 15px 9px;

        .slick-slide {
            height: auto;

            .sideBarActive {
                background: var(--primary);
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 0.9rem;
                line-height: 15px;
                color: #FFFFFF;
                height: 70px;
                display: flex;
                justify-content: center;
                vertical-align: middle;
                text-align: center;
                margin: 10px;
                cursor: pointer;
            }
        
            .sideBarInActive {
                background: var(--tertiary);
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 0.9rem;
                line-height: 15px;
                text-align: center;
                color: var(--primary);
                height: 70px;
                display: flex;
                justify-content: center;
                vertical-align: middle;
                text-align: center;
                margin: 10px;
                cursor: pointer;
            }

        }
        .slick-prev {
            left: 20px;
            z-index: 1;
        }

        .slick-next {
            right: 15px;
            z-index: 1;
        }

    }

    .description-modal-style-popup {
        
        // min-height: 240px;
        padding: 0.5rem 0.4rem;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 0.75rem;
        list-style-type: disc;
        table{
            margin: 1rem 0px;
            width: 100%;
        }
        th{
            background: var(--secondary);
            padding: 0.25rem 0.5rem;
            width: 33.33%;
        }
        td{
            background: var(--tertiary);
            padding: 0.25rem 0.5rem;
            vertical-align: text-top;
    
        }


        p {
            margin: 1rem 0px;
        }

        a {
            color: #4299e1;
        }
        
        
    }

}



.learningPageEligibility {
    .eligibility-block {
        background: none;
        padding: 0;
        border-radius: 0px;

        .title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 17px;
            color: var(--primary);
            padding: 1rem 0px 0rem 0px;
        }

        .description {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1px;
            color: rgba(0, 0, 0, 0.37);
            padding: 1rem 0px;
        }
    }
}

.learningPageCoverageDetails {
    .title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 18px;
        color: var(--primary);
    }

    .CoverageDetails-block {
        background: var(--tertiary);
        border-radius: 0px;
        // margin-top: 2rem;
        padding: 0px 20px;

        .title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 18px;
            color: var(--primary);
            padding: 1rem 0px 0rem 0px;
        }

        .description {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.37);
            padding: 1rem 0px;
        }
    }
    .CoverageDetails-block-modal {
        border-radius: 0px;
        padding: 1rem 0px;

        .title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 18px;
            color: var(--primary);
            padding-top: 0 !important;
        }

        .description {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.37);
        }
    }

    .block1 {
        .block-div {
            height: 10rem;
            padding: 1rem;

            .block-image {
                width: 34px;
                // height: 34px;
                margin: 20px 0px;
            }

            .block-text {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 17px;
                color: var(--primary);
                margin-bottom: 20px !important;
                text-align: left;
            }
        }
    }

    .CoverageDetailsPopup {
        background: #fff;
        position: fixed;
        bottom: 0px;
        z-index: 1;
        border: 1px solid green;
        border-radius: 33px 33px 0px 0px;
        padding: 15px 15px;
        box-shadow: 1rem 15rem 1rem 44rem rgb(0 0 0 / 25%);
        overflow-y: auto;

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .heading {
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 1rem;
                line-height: 17px;
                color: var(--primary);
                text-align: center;
            }
        }

        @media (min-width: 960px) {
            bottom: 10%;
            top: 10%;
            left: 30%;
            right: 30%;
            border-radius: 10px;
            padding: 2rem;
        }
    }
    
    .CoverageDetailsPopup::-webkit-scrollbar {
        width: 8px;
    }
    .CoverageDetailsPopup::-webkit-scrollbar-track {
        background: transparent;
    }
    .CoverageDetailsPopup::-webkit-scrollbar-thumb {
        background: #aeaeae;
        border-radius: 4px;
    }
    .CoverageDetailsPopup::-webkit-scrollbar-thumb:hover {
        background: #929292;
    }


    .CoverageDetailsPopup .lineGreen {
        margin: 0 auto;
        padding: 15px 0px;
    }

    .CoverageDetailsPopup .block-image {
        width: 66px;
        // height: 66px;
    }

    .CoverageDetailsPopup .title {
        font-weight: 600;
        font-size: 0.9rem;
        color: var(--primary);
        padding-top: 1rem;
    }

    .CoverageDetailsPopup .description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 17px;
        color: rgb(0 0 0 / 65%);
        text-align: justify;
        padding: 1rem 0px 0rem 0px;
    }

    .CoverageDetailsPopup .total {
        // padding-bottom: 25px;
        .backBtn {
            background: var(--secondary);
            font-weight: 600;
            font-size: 1rem;
            line-height: 24px;
            color: var(--primary);
            text-transform: uppercase;
            width: 100%;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            padding: 10px;
        }
    }

    .exitpopup{
        @media (min-width: 960px) {
            bottom: 30%;
            top: 30%;
            left: 30%;
            right: 30%;
            border-radius: 10px;
            padding: 2rem;
        }
        .total{
            padding-bottom: 5px;
        }
    }

}

@media (max-width: 768px) {

    .learningPageCoverageDetails .CoverageDetailsPopup {
        top: 20%;
        left: 0%;
        right: 0%;
    }

    .learningPageCoverageDetails .CoverageDetailsPopup::-webkit-scrollbar {
        display: none;
    }
    .learningPageCoverageDetails .block1 .block-div .block-text {
        font-size: 0.75rem;
    }
}

@media (max-width: 960px) {
    .learningPageCoverageDetails .CoverageDetailsPopup {
        top: 20%;
        left: 0%;
        right: 0%;
    }
}


@media (min-width: 768px) {
    .learningPageCoverageDetails {
        .block1 {
            .block-div {
                padding: 20px 50px;
                position: relative;
                .block-image {
                    width: 4rem;
                    // height: 4rem;
                    margin: 10px 0px 40px 0px;
                }
                .block-text {
                    font-size: 0.9rem;
                    position: absolute;
                    top: 110px;
                }
            }
            
        }
        .CoverageDetailsPopup {
            .description {
                text-align: justify;
            }
            .description.list {
              padding: 5px 0px;
            }
        }
    }
}