.voluntryPage {
  .data {
    padding: 30px 0px;
    
  .radioButtonOuter {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 15px;
  }
  .radioButtonOuter .radioButton {
    display: inline-flex;
    background: var(--tertiary);
    padding: 15px 15px;
    margin: 0.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 18px;
    color: var(--primary);
    align-items: center;
    width: 100%;

    background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
  background-size: 200%;
  transition: .25s ease-out;
  background-position: right;
  }
  .radioButtonOuter .radioButton .radioOuter {
    position: relative;
    margin-right: 15px;
  }
  .radioButtonOuter .radioOuter .radioCheck {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 7px;
    opacity: 0;
  }

  .radioButtonOuter .radioButton.active .radioCheck {
    opacity: 1;
  }
  .radioButtonOuter .radioButton.active {
    background: var(--primary);
    color: #fff;

    background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
  background-size: 200%;
  transition: .25s ease-out;
  background-position: left;
  }

}
}

.radioButtonOuterFamily {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 15px;

  .radioButton {
    // display: inline-flex;
    background: var(--tertiary);
    padding: 15px 15px;
    margin: 0.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 18px;
    color: var(--primary);
    // align-items: center;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;


    @media (max-width: 500px) {
      width: 100%;
    }

    background: linear-gradient(to left, var(--tertiary) 51%, var(--primary) 50%) right;
  background-size: 200%;
  transition: .25s ease-out;
  background-position: right;
  .coverages{
    text-align: left;
  }
  .relations{
    text-align: left;
  }
  .radioOuter {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-right: 15px;
  }
  .radioCheck {
    position: absolute;
    // left: 0;
    right: 5px;
    // margin: 0 auto;
    top: 7px;
    opacity: 0;
  }
  }
  .active {
    background: var(--primary);
    color: #fff;

    background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
  background-size: 200%;
  transition: .25s ease-out;
  background-position: left;
    .radioCheck {
    opacity: 1;
  }
}
}

