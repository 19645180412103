.beneficiaries {
  .title {
    color: var(--primary);
    font-size: 1rem;
    font-weight: 500;
  }
  .plusImage {
    width: 21px;
    height: 21px;
  }
  .box {
    background: #fafafa;
    .boxInner {
      border-bottom: 0.3px solid rgba(0, 0, 0, 0.37);
      .nameOuter {
        .name {
          color: var(--primary);
          font-weight: 600;
        }
        .relation {
          color: rgba(0, 0, 0, 0.37);
          font-weight: 400;
          font-size: 0.75rem;
          text-align: left;
        }
      }
      .imgOuter {
        align-items: center;
        .deleteImage {
          width: 22px;
          height: 22px;
        }
        .editImage {
          height: 20px;
          width: 20px;
        }
      }
    }
    .details {
      .nameOuter {
        .name {
          color: rgba(0, 0, 0, 0.37);
          font-weight: 400;
          font-size: 0.75rem;
        }
        .relation {
          color: var(--primary);
          font-weight: 600;
          font-size: 0.75rem;
        }
      }
    }
  }

  .box.active {
    background: var(--primary);
    .boxInner {
      .nameOuter {
        .name {
          color: #fff;
        }
        .relation {
          color: #fff;
        }
      }
      .imgOuter {
        display: none;
      }
    }
    .boxInner {
      border-color: #fff;
    }
    .details {
      .nameOuter {
        .name {
          color: #fff;
        }
        .relation {
          color: #fff;
        }
      }
    }
  }
  .imgOuterMainDependantpage{
    .edtbtn{
      font-size: 0.9rem;
      background: var(--primary);
      color: var(--secondary);
      text-align: center;
      width: 5rem;
      margin-bottom: 0.25rem;
      cursor: pointer;
    }
    .deletebtn{
      font-size: 0.9rem;
      background: var(--secondary);
      color: var(--primary);
      text-align: center;
      width: 5rem;
      cursor: pointer;
    }
  }
}

.beneficiaries.learningPage {
  .beneficiariesModal {
    .content {
      background: inherit;
    }
  }
  .learningPageCoverageDetails {
    .CoverageDetailsPopup {
      .total {
        .backBtn {
          width: 48%;
        }
      }
      .css-w5y50t-MuiFormControl-root {
        margin: 16px 0;
      }
    }
  }
  .agree {
    .subTitle {
      color: rgba(0, 0, 0, 0.37);
      font-size: 0.75rem;
    }
    .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
    .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
      color: var(--primary);
    }
  }
  .box.box2 {
    .boxInner {
      border: none;
      padding-bottom: 20px;
      .imgOuter {
        flex-direction: column;
        margin-top: 0.7rem;
        // justify-content: center;
        // align-items: center;
      }
      .nameOuter {
        .backBtn {
          background: #f3f3f3;
          font-weight: 600;
          font-size: 1rem;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.37);
          text-transform: uppercase;
          // width: 100%;
          padding: 10px;
          float: left;
        }
      }
    }
  }
  .box {
    .boxInner {
      .backBtn {
        background: transparent;
        color: var(--secondary);
        width: auto;
        padding-left: 0;
      }
      .imgOuterMain {
        .percentageImage {
          height: 100px;
        }
      }
    }
  }
  .myDocuments {
    .Side-bar {
      .fixedWidth {
        width: 100%;
      }
      .sideBarInActive {
        border: 1px solid var(--primary);
        color: rgba(0, 0, 0, 0.37);
        background: transparent;
      }
    }
    .box.active {
      .nameOuter {
        .relation {
          color: #d9d9d9;
        }
      }
    }
  }

  .summaryPage {
    .box.box2 {
      width: 46%;
      .boxInner {
        padding-bottom: 0px;
      }
      .summaryImgOuter {
        background: #f3f3f3;
        position: relative;
        padding: 5px 5px 5px 35px;
        .benPercentage {
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: var(--primary);
        }
        .benName {
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.37);
        }
        .benRln {
          font-weight: 300;
          font-size: 10px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.37);
        }
        .benAllc {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
        }
        .benPAllc {
          color: var(--primary);
        }
        .benCAllc {
          color: var(--secondary);
        }
        &:nth-of-type(2) {
          width: 35%;
        }
        &:nth-of-type(1) {
          width: 65%;
        }
        .summaryImg.deleteImage {
          position: absolute;
          top: 10px;
          left: 10px;
        }
      }
    }
    .box.box2.first {
      margin-right: 0.5rem;
    }
    .box.box2.second {
      margin-left: 0.5rem;
    }

    .box.box2.Count {
      background: var(--primary);
      .boxInner {
        padding-bottom: 20px;
        .nameOuter {
          .name {
            color: #fff;
            text-align: center;
            font-size: 2rem;
          }
          .relation {
            color: #fff;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.beneficiaries.learningPage {
  .summaryPage.myBeneficiaries {
    .box.box2 {
      width: 100%;
      .myBeneficiariesInner {
        background: #fff;
        .summaryImgOuter {
          background: transparent;
          padding: 10px 0px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .beneficiaries {
    .box {
      .details {
        .nameOuter {
          .relation {
            font-size: 0.65rem;
          }
          .name {
            font-size: 0.65rem;
            color: var(--primary);
          }
        }
      }
    }
  }
  .beneficiaries.learningPage {
    .Side-bar {
      .sideBarActive {
        height: 50px;
        padding: 20px 15px;
      }
      .sideBarInActive {
        padding: 20px 10px;
        height: 50px;
      }
      .list-menu {
        font-size: 0.75rem;
      }
    }
    .agree {
      .aggreText {
        width: 80%;
      }
    }

    .summaryPage {
      .box.box2 {
        width: 100%;
      }
      .box.box2.first {
        margin-right: 1rem;
      }
      .box.box2.second {
        margin-left: 1rem;
      }
    }
  }
}
.my_doc_tabl {
  tbody,
  thead {
    tr {
      td {
        padding-inline-start: 0.2rem;
      }
    }
  }
  tr:nth-child(even) {
    background-color: #dadada;
  }
  tr:last-child {
    border-bottom: 1px solid #dadada;
  }
  transition: all 350ms cubic-bezier(0.08, 1.09, 0.32, 1.275);
}
.accordion__icon,
.noDoc_txt,
.accordion__content {
  transition: all 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}
