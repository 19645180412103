.policyCoverPage {
  .coverTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.9rem;
    /* identical to box height */

    color: var(--primary);
  }
  .coverDescription{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 0.9rem;
text-align: justify;

color: rgba(0, 0, 0, 0.37);

  }
}
