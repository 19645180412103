.homePageOld {
  .healthPlans {
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--primary);
    }
    .viewAll {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: var(--primary);
      margin-top: 5px;
      .arrow {
        padding-left: 10px;
        width: 30px;
        margin-top: 5px;
        height: fit-content;
      }
    }
    .box {
      background: var(--primary);
      color: #fff;
      .lifeInsurance {
        width: 44px;
      }
      .enrollBtn {
        background-color: var(--secondary);
        height: 20px;
        width: 52px;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: var(--primary);
      }
      .more {
        font-size: 0.9rem;
        background: var(--secondary);
        color: var(--primary);
        padding: 0 0.4rem;
      }
      .boxTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }
      .checkRight {
        margin-top: -5px;
        height: fit-content;
      }
      .box1 {
        background: rgba(148, 152, 149, 0.3);
        padding: 10px;
        .box1Title {
          font-style: normal;
          font-weight: 400;
          font-size: 8px;
          line-height: 12px;
          color: var(--secondary);
        }
        .name {
          font-style: normal;
          font-weight: 400;
          font-size: 8px;
          line-height: 12px;
          color: #ffffff;
        }
        .dot {
          width: 6px;
          height: 6px;
          margin-top: 2px;
          margin-right: 2px;
        }
      }
      .box2 {
        background: rgba(148, 152, 149, 0.3);
        padding: 10px;
        .borderR {
          .name {
            font-style: normal;
            font-weight: 400;
            font-size: 8px;
            line-height: 12px;
          }
          .date {
            font-size: 10px;
          }
        }
      }
    }
    .boxShadow {
      box-shadow: 0px 3px 10px #00000029;
      border-radius: 5px;
    }
    .color1 {
      background: var(--selectedTileColor);
    }
    .color2 {
      background: #ffe6e6;
    }
    .color3 {
      background: var(--tertiary);
    }
    .color4 {
      background: var(--tertiary);
    }

    .block-div {
      padding: 1rem;
      position: relative;
      .block2-image-div {
        display: flex;
        justify-content: center;
        position: relative;
        .block-image {
          width: 34px;
          margin-bottom: 30px;
        }
      }

      .block2-sub_text {
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: var(--secondary);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5px;
      }
      
      .block2-text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--primary);
        padding-top: 1rem;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
      }
      
    }
    .moreBenefits {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--primary);
    }
    .block-div.color4 {
      height: 100px;
    }

    .moreBenefitsBox {
      .block-div {
        .block2-text {
          font-size: 9px;
        }
      }
    }
  }

  .block {
    border: 1px solid green;
    margin: 0.3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .button {
      border: 1px solid red;
      width: 12rem;
    }
  }
}

.is-hidden {
  display: none;
}

.fullwidth {
  grid-column: 1 / -1;
}

.tile_grid {
  display: grid;
  gap: 1rem;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, 14rem);
  /* justify-content: center; */

  justify-content: space-between;
}

.none {
  display: none !important;
}

@media (min-width: 768px) {
  .homePageOld {
    .healthPlans {
      .block-div {
        .block2-image-div {
          .block-image {
            width: 4rem;
            height: 4rem;
            margin-bottom: 50px;
          }
        }
        .block2-text {
          font-size: 1rem;
          bottom: 25px;

          width: 90%;
          margin: auto;
        }
        .block2-sub_text {
          font-size: 0.75rem;
          bottom: 10px;
        }
      }
      .moreBenefitsBox {
        .block-div {
          .block2-text {
            font-size: 1rem;
          }
          .block2-sub_text {
            font-size: 0.75rem;
            bottom: 10px;
          }
        }
      }
      .block-div.color4 {
        height: 150px;
      }
      .box {
        .lifeInsurance {
          width: 55px;
        }
        .boxTitle {
          font-size: 1rem;
        }
        .enrollBtn {
          font-size: 0.75rem;
          width: 80px;
          height: 30px;
        }
        // .more {
        //   font-size: 0.75rem;
        //   padding-top: 10px;
        // }
        .box1 {
          .box1Title {
            font-size: 1rem;
          }
          .name {
            font-size: 1rem;
            padding-bottom: 10px;
            .dot {
              width: 8px;
              height: 8px;
              margin-right: 5px;
            }
          }
          .pt-2 {
            padding-top: 1rem;
          }
        }
        .box2 {
          .borderR {
            .name {
              font-size: 1rem;
            }
            .date {
              font-size: 1rem;
            }
          }
          .pt-2 {
            padding-top: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .tile_grid {
    display: grid;
    gap: 1rem;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fit, 10rem);
    justify-content: space-between;
  }
}
