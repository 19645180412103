body {
  font-family: "Poppins", sans-serif;
}

.enrollmentIntroData {
  .enrollBtn {
    button {
      padding: 7px 5px; 
      .btnText {
        letter-spacing: 0px;
      }
    }
  }
  .data1 {
    padding: 15px 15px;

    .data1Inner {
      background: var(--tertiary);
      padding: 0px 20px;
      padding-top: 20px;
    }
    .dataInneer {
      .title {
        margin-top: 2rem;
        font-style: normal;
        font-weight: 600;
        font-size: 0.9375rem;
        color:  var(--primary);;
      }
      .desc {
        font-weight: 500;
        font-size: 0.75rem;
        color: rgba(0, 0, 0, 0.37);
        padding: 2rem 0px;
      }
      .moreDetails {
        background: var(--tertiary);
        padding: 15px 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 0.625rem;
        line-height: 15px;
        color: var(--primary);
      }
    }

    .medicalImage {
      padding-top: 10px;
    }
    .name {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.71);
      padding-bottom: 5px;
    }
    .date {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.37);
      padding-left: 10px;
    }
  }

  .cardOuter {
    .card {
      .heading {
        font-weight: 600;
        font-size: 0.625rem;
        line-height: 18px;
        color: #000000;
        padding: 5px 0;
      }
      .desc {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.37);
      }
      .cardInner {
        padding: 10px 10px;
        min-height: 151px;
      }      
    }
    .one {
        .cardInner {
          background: #ffe6e6;
        }
      }
      .two {
        .cardInner {
          background: #fcf8e8;
        }
      }
      .three {
        .cardInner {
          background: #f6fbf4;
        }
      }
      .four {
        .cardInner {
          background: #daeaf1;
        }
      }
      .five {
        .cardInner {
          background: #eaf6f6;
        }
      }
      .six {
        .cardInner {
          background: #ffe6e6;
        }
      }
      .seven {
        .cardInner {
          background: #fcf8e8;
        }
      }
      .eight {
        .cardInner {
          background: #f6fbf4;
        }
      }

    .titleInner {
      font-weight: 500;
      font-size: 0.9375rem;
      line-height: 22px;
      color: #000000;
    }
  }

  .learnMore {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 18px;
    text-decoration-line: underline;
    color: var(--primary);
  }
  .next {
    background: var(--secondary);
    width: 100%;
    padding: 15px 20px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    color: var(--primary);
  }

  .data2{
    margin: 1rem;
    background: var(--tertiary);
    margin-top: 10rem;
    .completedEnrollHead{
      padding: 2rem 1rem;
      text-align: center;
      color: var(--primary);
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
  
}



