

.main_div {

    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 25rem);
    justify-content: space-evenly;
    img{
        /* Start the shake animation and make the animation last for 0.5 seconds */
        animation: shake 1s;
               
        /* When the animation is finished, start again */
        // animation-iteration-count: infinite;
       }

       .border {
        border: 1px solid;
    }
}

.main_div>h3 {
    width: 100%;
    /* margin: 0 auto; */
    font-weight: 500;
    text-align: center;
}

.date_ofMarriage {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.8rem;
    background: #FAFAFA;
}

.date_ofMarriage>input {
    width: 100%;
    background: inherit;
    outline: none;
    font-family: "Poppins";
    color: rgba(0, 0, 0, 0.37);
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-4deg); }
    20% { transform: translate(-3px, 0px) rotate(4deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(4deg); }
    50% { transform: translate(-1px, 2px) rotate(-4deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-4deg); }
    80% { transform: translate(-1px, -1px) rotate(4deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-4deg); }
  }