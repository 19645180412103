.messageInput {
    display: flex;
    border: 1px solid gainsboro;
    justify-content: space-between;
    padding: 5px 11px;
    align-items: center;
    font-family: 'Poppins';
    border-radius: 5px;
    background: var(--tertiary);
 
    input {
        flex: 1;
        background: none;
        outline: none;
    }

  

    svg {
        font-size: 1.5rem;
        margin: 0.3rem 0;
        transition: all .1s linear;
        height: 34px;
        margin: 0;
        width: 50px;
        padding: 4px;
        border-radius: 34px;
        display: flex;
        color: grey;
        align-items: center;
        justify-content: center;
    }

    // svg:hover{
    //     cursor: pointer;
    //     border: 1px solid;
    //     background-color: #fff;
    // }
}

.message {
    border: 1px solid gainsboro;
    max-width: 88%;
    border-radius: 15px;
    padding: 0.4rem;
    background: rgb(0 0 0 / 8%);
    font-size: 14px;
    margin: 0 5px;
}