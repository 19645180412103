.loginBlock {

  .input {
    background-color: var(--tertiary);
    height: 2.81rem;
    width: 100%;
    padding-left: 12px;
    font-size: 18px;    
  }
  .eyeIcon{
    position: absolute;
    background-color: var(--tertiary);
    top: 0;
    font-size: 1rem;
    right: 0.5rem;
    cursor: pointer;
    width: 2rem;
    height: 90%;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      color: var(--primary);
    }
  }
  button.login {
    background: var(--primary);
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 30px;
    color: #ffffff;
    width: 100%;
    padding: 10px 0;
  }
  .link {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 21px;
    color: var(--primary);
  }
  .loginBtnOuter {
    button {
      padding: 12px 22px;
    }
  }

  .container{
    .orOuter{
      margin-top: 1.2rem;
      hr{
        border-color: var(--primary);
      }
      .orInner{
        margin: 0px auto;
        margin-top: -0.75rem;
        width: 2.5rem;
        text-align: center;
        background-color: #ffffff;
        color: var(--primary);
        font-weight: 600;
         
      }
    }
  }
}

.headerLogin {
  background: var(--secondary);

  .headerLoginInner {

    .right {
      display: flex;
      align-items: center;
    }
    .logoImg {
      margin: 1rem auto;
      padding: 1.5rem 0;
    }
    .left {
      .text {
        font-weight: 600;
        font-size: 1.5rem;
        color: var(--primary);
        padding-left: 25px;
        .textInner {
          font-weight: 400;
        }
      }
      .desc {
        font-weight: 300;
        font-size: 0.75rem;
        line-height: 20px;
        color: var(--primary);
        padding-left: 25px;
      }
    }
  }
 
}
