.voluntryAddParentsPage {
  .data {
    padding: 30px 1rem;
    .bottomButtonReq {
      padding: 0;
    }
    .PageTitle-And-Description {
      margin-left: 0;
    }
    
  .radioButtonOuter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    // padding: 0 8px;
  }
  .radioButtonOuter .radioButton {
    display: inline-flex;
    // background: var(--tertiary);
    padding: 15px 15px;
    margin: 0.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 18px;
    color: var(--primary);
    align-items: center;
    width: 49.1%;
    .subTitle {
      font-style: normal;
      font-weight: 400;
      font-size: 0.625rem;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.37);
      // padding: 15px 0;
  }
  background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
  background-size: 200%;
  transition: .25s ease-out;
  background-position: right;
  }
  .radioButtonOuter .radioButton .radioOuter {
    position: relative;
    margin-right: 15px;
  }
  .radioButtonOuter .radioOuter .radioCheck {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 7px;
    opacity: 0;
  }

  .radioButtonOuter .radioButton.active .radioCheck {
    opacity: 1;
  }
  .radioButtonOuter .radioButton.active {
    background: var(--primary);
    color: #fff;
    background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
  background-size: 200%;
  transition: .25s ease-out;
  background-position: left;
   
    .subTitle{
      color: #fff;
    }
  }

  .linksOuter {
    // padding: 30px 0px;
    display: flex;
    justify-content: space-between;
  
    .addLink {
      font-style: normal;
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 18px;
      text-decoration-line: underline;
      color: var(--primary);
      cursor: pointer;
    }
    .viewLink {
      font-style: normal;
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 18px;
      text-decoration-line: underline;
      color: var(--primary);
      cursor: pointer;
    }
  }

}
.enrollValidations{
  text-align: center;
  color: var(--warning);
  font-weight: 600;
}
.addLink-outside-text{
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 18px;
  text-decoration-line: underline;
  color: var(--warning);
  cursor: pointer;
  text-align: end;
  margin-top: 1rem;
  margin-right: 1rem;
}
}
