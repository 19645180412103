.AddBeneficiaryMainPage {
  .btnOuter {
    background: #e5e5e5;
  }
  .btnOuter {
    .btn {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 21px;
      color: var(--primary);
      text-align: center;
      width: 50%;
      padding: 15px 0px;
      text-transform: capitalize;
    }

    .active {
      background: var(--primary);
      color: #fff;
    }
  }

  .beneficiaryPrimary {

    width: 100%;

    .agree {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0rem;
        .aggreText {
            width: 80%;
            .title{
              font-family: "Roboto","Helvetica","Arial",sans-serif;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.5;
              letter-spacing: 0.00938em;
              color: #9aa3c1;;
            }
          }
        .subTitle {
          color: rgba(0, 0, 0, 0.37);
          font-size: 0.75rem;
        }        
      }

    .selectDependant {
      margin: 1rem 0px;
      @media (min-width: 500px) {
        width: 50%;
        margin-left: 50%;
      }
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
  .beneficiaryEditMode {
    background-color: var(--primary);
    color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    .edit-ben-dependant{
      font-size: 0.75rem;
      background-color: var(--secondary);
      color: var(--primary);
      margin-left: 0.5rem;
      padding: 0.1rem 0.25rem;
      border-radius: 0.41rem;
      cursor: pointer;
    }
    .ben-ben-subtext{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 18px;
        color: #FFFFFF;        
        margin-bottom: 0.55rem;

    }
    .row1 {
      padding: 0.5rem 0rem;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      .name {
        font-size: 1rem;
        font-weight: 600;
        .relation {
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
      .ben-percent-subtext{
        margin-top: 1rem;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 18px;
        color: #FFFFFF;
      }
        .pdiv{
          display: flex;
          align-items: center;
          .selectPercentage {
            display: flex;
            flex-direction: row;
            button {
              min-width: 1rem;
              margin: 0px 0.25rem;
              padding: 0.5rem;
              background-color: rgba(255, 255, 255, 0.76);
              color: var(--primary);
            }
          }
          .row3{
            margin-left: 1rem;
          }
        }
     
    }
    .row2 {
      padding: 0.5rem 0rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .row3 {
      padding: 0.5rem 0rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .beneficiary-message {
        margin-right: 1rem;
      }
      .btn-done {
        background-color: var(--secondary);
        margin: auto;
        height: 2.5rem;
        padding: 0px 1rem;
        color: var(--primary);
        cursor: pointer;
      }
      .btn-cancel {
        background-color: var(--tertiary);
        margin: auto;
        height: 2.5rem;
        padding: 0px 1rem;
        margin-left: 0.5rem;
        color: var(--primary);
        cursor: pointer;
      }
    }
  }

  .addedBeneficiaries {
    .benCard {
      // background-color: #fafafa;
      background-color: var(--tertiary);
      color: var(--primary);
      margin: 1rem 0rem;
      // padding: 0px 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 4.5rem;
      .col1 {
        .name {
          padding: 0.5rem 1rem;
          font-size: 1rem;
          font-weight: 600;
          .relation {
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
        .type {
          padding: 0.5rem 0rem;
          width: 9rem;
          padding: 0.5rem 0rem;
          background-color: #d9d9d9;
          text-align: center;
          color: rgba(0, 0, 0, 0.37);
          font-weight: 600;
        }
      }
      .col2 {
        display: flex;
        flex-direction: row;
        .forStyleOnly{
          width: 1rem;
          margin-right: -8px;
          // background-color: #fafafa;
          background-color: var(--tertiary);
          z-index: 1;
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
        .editDelete {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          // margin: 0px 0.5rem;
          padding: 0.5rem 1rem;
          padding-left: 1.5rem;
          background-color:var(--primary);
          svg{
          &:first-of-type{
            cursor: pointer;
            font-size: 1.2rem;
            color: var(--tertiary);
          }
          &:nth-of-type(2){
            cursor: pointer;
            font-size: 1.25rem;
            color: var(--secondary);
          }
          }
          
        }
      }
    }
    .deletingCard{
        animation: zoom forwards 1s ease-out 1;
      }
  }
  
}

@keyframes zoom { 
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.25);
    opacity: 0.5;
  }  
}

.beneficiaryPrimary {

  width: 100%;
.title-allocate{
  font-size: 1.25rem;
  font-weight: 500;
}
  .agree {
      display: flex;
      justify-content: space-between;
      margin: 1rem 0rem;
      .aggreText {
          width: 80%;
        }
      .subTitle {
        color: rgba(0, 0, 0, 0.37);
        font-size: 0.75rem;
      }        
    }

  .selectDependant {
    margin: 1rem 0px;
    @media (min-width: 500px) {
      width: 50%;
      margin-left: 50%;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}