.homePage {
  padding-top: 1rem;
  .blocks {
    background-color: var(--tertiary);
    height: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 2rem;
      min-width: 2rem;
      padding-bottom: 10px;
    }
    span {
      font-family: "Poppins";
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;

      color: var(--primary);
    }
  }
  .enroll {
    background-color: var(--primary);
    span {
      color: var(--tertiary);
    }
  }

  .bannerImage {
    img {
      height: 50%;
    }
  }

  .slick-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    li {
      width: 1rem;
      height: 0.4rem;
      background-color: var(--secondary);
      margin: 0px 3px;
    }
    .slick-active {
      width: 2rem;
      height: 0.4rem;
      background-color: var(--primary);
      margin: 0px 3px;
    }
  }
  .slick-dots li button:before {
    color: #ffffff;
  }

  .sliderBlock {
    .forSlider {
      position: relative;
      .bannerTextBlock {
        position: absolute;
        height: 100%;
        width: 34%;
        min-width: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 1rem;
        top: 0;
        // left: 5%;
        background-color: rgb(255 255 255 / 37%);

        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 24px;
          color: #000000;
        }
        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.33);
        }
        button {
          padding: 0px;
        }
      }
    }
  }

  .password-profile-div{
    position: relative;
    .eyeIcon{
      color: var(--primary);
      position: absolute;
      padding: 0.63rem;
      top: 1.1rem;
      right: 0;
      cursor: pointer;
      // background-color: var(--tertiary);
    }
  }
}

@media (min-width: 768px) {
  .homePage {
    .blocks {
      height: 9rem;
      position: relative;
      img {
        width: 4rem;
        padding-bottom: 20px;
      }
      span {
        font-size: 16px;
        position: absolute;
        bottom: 20px;
      }
    }
  }
}

.elligible-plans-tabs{
  display: flex;
  justify-content: flex-start;
  .tab-plans{
     font-family: 'poppins';
     font-size: 0.9rem;
     font-weight: 500;
     color: rgba(0, 0, 0, 0.37);
     padding: 0.5rem 1rem;
     background-color: var(--tertiary);
     margin-right: 1rem;
     cursor: pointer;
  }
  .active{
    background-color: var(--primary);
    color: var(--tertiary);
  }
}

.homePageOld {
  .healthPlans {
    
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--primary);
    }
    .viewAll {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: var(--primary);
      margin-top: 5px;
      .arrow {
        padding-left: 10px;
        width: 30px;
        margin-top: 5px;
        height: fit-content;
      }
    }
    .box {
      background: var(--primary);
      color: #fff;
      .lifeInsurance {
        width: 44px;
      }
      .enrollBtn {
        background-color: var(--secondary);
        height: 20px;
        width: 52px;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: var(--primary);
      }
      .more {
        font-size: 0.9rem;
        background: var(--secondary);
        color: var(--primary);
        padding: 0 0.4rem;
      }
      .boxTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }
      .checkRight {
        margin-top: -5px;
        height: fit-content;
      }
      .box1 {
        background: rgba(148, 152, 149, 0.3);
        padding: 10px;
        .box1Title {
          font-style: normal;
          font-weight: 400;
          font-size: 8px;
          line-height: 12px;
          color: var(--secondary);
        }
        .name {
          font-style: normal;
          font-weight: 400;
          font-size: 8px;
          line-height: 12px;
          color: #FFFFFF;
        }
        .dot {
          width: 6px;
          height: 6px;
          margin-top: 2px;
          margin-right: 2px;
        }

      }
      .box2 {
        background: rgba(148, 152, 149, 0.3);
        padding: 10px;
        .borderR {
          .name {
            font-style: normal;
            font-weight: 400;
            font-size: 8px;
            line-height: 12px;
          }
          .date {
            font-size: 10px;
          }
        }
      }
    }

    .color1 {
      background: var(--selectedTileColor);
    }
    .color2 {
      background: #FFE6E6;
    }
    .color3 {
      background: var(--tertiary);
    }
    .color4 {
      background: var(--tertiary);
    }
    
    .block-div {
      padding: 1rem;
      position: relative; 
      .block2-image-div {
        display: flex;
        justify-content: center;
        position: relative;
        .block-image {
          width: 34px;
          margin-bottom: 30px;
        }
      }
      .block2-text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--primary);
        padding-top: 1rem;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
      }
      .block2-sub_text {
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: var(--secondary);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5px;
      }
    }
    .moreBenefits {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--primary);
    }
    .block-div.color4 {
      height: 100px;
    }
    .moreBenefitsBox {
      .block-div {
        .block2-text {
          font-size: 9px;
        }
      }
    }
  }
}

.none {
  display: none !important;
}


@media (min-width: 768px) {
  .homePageOld {
    .healthPlans {
      .block-div {
        .block2-image-div {
          .block-image {
            width: 4rem;
            margin-bottom: 50px;
          }
        }
        .block2-text {
          font-size: 1rem;
          bottom: 25px;
        }
        .block2-sub_text {
          font-size: 0.75rem;
          bottom: 10px;
        }
      }
      .moreBenefitsBox {
        .block-div {
          .block2-text {
            font-size: 1rem;
          }
          .block2-sub_text {
            font-size: 0.75rem;
            bottom: 10px;
          }
        }
      }
      .block-div.color4 {
        height: 150px;
      }
      .box {
        .lifeInsurance {
          width: 55px;
        }
        .boxTitle {
          font-size: 1rem;
        }
        .enrollBtn {
          font-size: 0.75rem;
          width: 80px;
          height: 30px;
        }
        // .more {
        //   font-size: 0.75rem;
        //   padding-top: 10px;
        // }
        .box1 {
          .box1Title {
            font-size: 1rem;
          }
          .name {
            font-size: 1rem;
            padding-bottom: 10px;
            .dot {
              width: 8px;
              height: 8px;
              margin-right: 5px;
            }
          }
          .pt-2 {
            padding-top: 1rem;
          }
        }
        .box2 {
          .borderR {
            .name {
              font-size: 1rem;
            }
            .date {
              font-size: 1rem;
            }
          }
          .pt-2 {
            padding-top: 1rem;
          }
        }
      }
    }
  }
}


.slick-slide {
  height:300px;
}

.slick-slide img {
  height:300px;
}
.slick-slide{
.bannerImage{
  img{
    height:300px;
    width: 100%;
  }
}}

@media (max-width: 500px) {

  .slick-slide {
    height:200px;
  }
  
  .slick-slide img {
    height:200px;
  }
  .slick-slide{
  .bannerImage{
    img{
      height:200px;
      width: 100%;
    }
  }}

}

.planPopUpHeading{
  font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 24px;
      color: var(--primary);
}
.planPopUpdescription{
  margin: 1rem 0px;
  margin-left: 1rem;

      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 24px;
      color: #8f8989;
      .noDependantWarning{
      //   font-style: normal;
      // font-weight: 600;
      // font-size: 0.75rem;
      // line-height: 18px;
      color: red;
      }
      .planNameInPopUp{
        font-size: 0.84rem;
      }
}
.planPopUpBtns{
  margin: 1rem auto;
  .yesbtn{
    padding: 0.25rem 2rem;
    background-color: var(--primary);
    color: #ffffff;
    margin: 0.5rem 0.7rem;
    border-radius: 2rem;
  }
  .nobtn{
    padding: 0.25rem 2rem;
    background-color: var(--secondary);
    color: #ffffff;
    margin: 0.5rem 0.7rem;
    border-radius: 2rem;
  }
}

.diagonalBadgeInPlansPage{
 
  background: #ff6f00;  
  position: absolute;
  padding: 2px 5px;
  min-width: 100px;
  max-width: 50%;
  white-space: normal;
  top: -7.5%;
  right: 0%;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75rem !important;
  box-sizing: border-box;
  
}

.planPageviewLink {
  margin-top: 1rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 18px;
  text-decoration-line: underline;
  color: var(--primary);
  display: flex;
  justify-content: space-between;
    span{    
      cursor: pointer;
    }
    span:first-child{
      margin-left: 2rem;
    }
    
}

.planPleaseComplete{
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.37);
  margin-bottom: 1.4rem;
}
.userDisclaimer{
  text-align: justify;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.37);
  margin-bottom: -1rem;
}

.planWizardsMainPage{
  .wizardsSubTitlePlans{
    font-size: 0.83rem;
  }
  hr{
    margin: 0.5rem 0px;
    border-color: var(--tertiary);
  }
  .editBlock{    
    
    @media(min-width:500px){
      position: absolute;
      right: 1rem;
      bottom: 1rem;
    }
    @media(max-width:500px){
      display: flex;
    justify-content: flex-end;
    }
      .editWizardButton{
        padding: 0.45rem 1.45rem;
        cursor: pointer;
        font-weight: 500;
    
    color: var(--primary);
    background-color: var(--secondary);
      }
  }
  .vertical-timeline-element-date{
    display: none;
  }
  .planWizardsEmpEnrolled{
    color: var(--tertiary);
  }
  .planWizardsEmpCoverage{
    color: var(--primary);
  }

  .vertical-timeline-element-title{
    @media (max-width:500px){
      margin-top: 0.5rem;
    }
  }
 
      .benSumInFinalEnroll{
    table{
      thead{
        font-family: "Poppins";
       
        font-style: normal;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 16px;
  
        color: var(--primary);
      }
      tbody{
        font-family: "Poppins";
        
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 16px;
  
        color: var(--primary);
      }
      td{
        padding: 0.25rem 0.5rem !important;
      }
      th{
        padding: 0px 0.5rem;
      }
    }
  
    
  }
.bottomButtonReq{
  position: fixed;
  left: 50%;
    bottom: 3%;
    transform: translate(-50%, -50%);
  max-width: 760px;
  padding: 1rem;
  background-color: #fff;
  
}
  
  
}

.bencoinBlock{
  .benEnroll{
    background-color: var(--primary);
    span{
      color: var(--tertiary);
      bottom: 10px;
    }
    
  }
  
  .title{
    color: var(--primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
