.inbox-chat{
    height: calc(100vh - 9rem);
    
    .inbox-msg-main{
        height: 100%;
        display: flex;
        flex-direction: column;
        .chat-section{
            overflow-y: scroll;
        }
        .input-chat-section{
            @media(min-width:500px){
                    margin-bottom: -1rem;
                }
        }
    }
    
}

