.forgotPasswordBlock {
  .input {
    background-color: var(--tertiary);
    height: 45px;
    width: 100%;
    padding-left: 12px;
  }
  button.login {
    background: var(--primary);
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 30px;
    color: #ffffff;
    width: 100%;
    padding: 10px 0;
  }
  .link {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 21px;
    color: var(--primary);
  }
}

.headerLogin {
  background: var(--secondary);
  .headerLoginInner {
    .right {
      display: flex;
      align-items: center;
    }
    .logoImg {
      margin: 1rem auto;
      padding: 1.5rem 0;
    }
    .left {
      .text {
        font-weight: 600;
        font-size: 1.5rem;
        color: var(--primary);
        padding-left: 25px;
        .textInner {
          font-weight: 400;
        }
      }
      .desc {
        font-weight: 300;
        font-size: 0.75rem;
        line-height: 20px;
        color: var(--primary);
        padding-left: 25px;
      }
    }
  }
}
