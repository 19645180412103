
@import './enrollIntro.scss';
@import './header.scss';
@import './footer.scss';
@import './login.scss';
@import './basicmedical.scss';
@import './adddependent.scss';
@import './pageheader.scss';
@import './PageTitleAndDescription.scss';

@import './voluntary/voluntry.scss';
@import './voluntary/voluntryAddParents.scss';
@import './voluntary/coveragetype.scss';
@import './voluntary/individualFinal.scss';
@import './voluntary/rateCardPopup.scss';

@import './groupPlanFinalPage.scss';
@import './askForIndividualPage.scss';
@import './individualPlanFinal.scss';
@import './longTermDisability.scss';
@import './shortTermDisability.scss';
@import './lifeInsurance.scss';
@import './addBeneficiary.scss';
@import './policyCover.scss';
@import './chooseCompany.scss';
@import './homePage.scss';
@import './homeOld.scss';
@import './forgotPassword.scss'; // page title on every page
@import "./Events//event-competed.scss";
@import "./Events/event-report.scss";
@import "./displayPlan.scss";
@import "./Inbox/inbox.scss";
@import "./addBeneficiaryAfterPlanEnroll.scss";
@import "./profile.scss";
@import "./Reimbursement/index.scss";
@import "./Reimbursement/clamHistory.scss";
@import "./Reimbursement/uploadDetails.scss";
@import "./Reimbursement/enroll.scss";
@import "./CarLeaseProgram/index.scss";

@import "./LearningPages/leavepopups.scss";
@import "./contactUs.scss";
@import "./coinFlow.scss";

@import "./newLearn/newLearnTabs.scss";
@import "./benModal.scss";

@import "./Beneficiaries/beneficiarySideBar.scss";

@import "./calender.scss";

@import "./inbox.scss";

// ***** for dynamic themes start *****
:root {
  --primary: #1A4D2E;
  --secondary: #FFD24C;
  --tertiary: #f5f7f7;

  --warning:red;
  --selectedTileColor: #e7f6ff;
  --footerBackground: #FAF3E3;

}
// ***** for dynamic themes end *****

.page-heading-navbar {
  position: fixed;
  z-index: 1001;
  top: 0.75rem;
  left: 50%;
  transform: translateX(-50%);

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  /* identical to box height */
  color: var(--primary);
  @media(max-width: 500px){
    left: 48%;
    font-size: 0.9rem;
  }
}

.radio{
  min-width: 1.5rem;
}


.bottomButtonReq {  
  width: 100%;
  padding: 0 1rem;
}
button.MuiButtonBase-root {
  border-radius: 0px;
}
.App {
  background: #F3F2EF;
}
.bg-white {
  background: #fff;
}
.gap-1 {
  gap: 0.5rem;
}
.gap-2 {
  gap: 1rem;
}
.homePage .p-4.m-4 .bottomButtonReq {
  padding: 0;
}
.css-zow5z4-MuiGrid-root>.MuiGrid-item {
  padding-top: 0px !important;
}
.css-1nc81py-MuiFormControl-root-MuiTextField-root .MuiInputBase-input {
  background: #fff !important;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root {
    box-shadow: none !important;
}
.css-46bh2p-MuiCardContent-root {
  padding: 16px 0px !important;
}
.font-20 {
  font-size: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.css-zow5z4-MuiGrid-root>.MuiGrid-item {
  padding-bottom: 10px !important;
}
.homePage.homePageOld .page-heading-navbar {
     transform: translateX(-50%);
   }

   .popupTotalCost{
    .viewCostContentMsg{
      font-family: 'poppins';
      font-weight: 500;
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.37);
      // padding: 5px 0px;
      padding-bottom: 0.5rem;
    }
   }

@media (min-width: 768px) {
  // .learningPage .page-heading-navbar.left {
  //   transform: translateX(-380%);
  // }
  .homePage .page-heading-navbar {
    transform: translateX(-380%);
  } 
  .homePage .page-heading-navbar.updateProfileTitle {
    transform: translateX(-300%);
  }
  .homePage .page-heading-navbar.ProfileTitle {
    transform: translateX(-670%);
  }
  .css-u5bwv4-MuiStack-root {
    padding: 0rem 1rem;
    margin: 0 !important;
  }
  .css-yskrxp-MuiStack-root {
    width: 100% !important;
  }
  .css-480o17-MuiGrid-root>.MuiGrid-item {
    padding-top: 0px !important;
  }

}

