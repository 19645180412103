.individualPlanFinal {
  .cardsBlock {
    width: 100%;
    display: grid;
    grid-template-columns: 49% 49%;
    @media (max-width:500px){
      grid-template-columns: 48% 48%;
    }
    gap: 1rem;
    transition: ease-in-out 1s;
    margin-top: 1rem;

    .coverageCard {
      background: var(--tertiary);
      // width: 48%;
      padding: 0.75rem;
      transition: ease-in-out 1s;
      .upperSection {
        margin: 0.5rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left {
          .title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 15px;
            /* identical to box height */
            color: rgba(0, 0, 0, 0.51);
          }
          .description {
            margin-top: 0.5rem;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 0.9rem;
            line-height: 18px;
            /* identical to box height */
            color: var(--primary);
          }
        }
        .right {
          // p {
          //   // background-color: var(--secondary);
          //   color: var(--secondary);
          //   font-family: "Poppins";
          //   font-style: normal;
          //   font-weight: 400;
          //   font-size: 0.9rem;
          //   line-height: 15px;
            
          //   padding: 2px 5px;
          // }
        }
      }

      .lowerSection {
        margin: 0.5rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left {
          .title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 15px;
            /* identical to box height */
            color: rgba(0, 0, 0, 0.51);
          }
          .description {
            margin-top: 0.5rem;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 0.9rem;
            line-height: 18px;
            /* identical to box height */
            color: var(--primary);
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          
          button {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 15px;
            background: #077dfa;
            color: #ffffff;
            /* background: var(--secondary); */
            padding: 3px 1rem;
            margin: 5px 0px;
            border-radius: 10px;
          //   &:hover{
          //   &:not(button):hover{
          //     background: #2b91ff;
          //   }
          // }          
          }
          &:hover {
            button:not(:hover){
            background: #2b91ff;
          }}
        }
        
      }
    }   
    .deletingCard{
      animation: zoom forwards 1s ease-out 1;
    }
    .editingCard{
      // animation: zoom forwards 1s ease-out 1;
      transform: rotateY(90deg);
    }
  }

  .cardsBlock-group {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    @media (max-width:500px){
      grid-template-columns: 98%;
    }
    gap: 1rem;
    transition: ease-in-out 1s;
    margin-top: 1rem;
  .coverageCard-group {
    background: var(--tertiary);
    // width: 48%;
    padding: 0.75rem;
    transition: ease-in-out 1s;
    .upperSection {
      margin: 0.5rem 0;
      display: flex;
      flex-direction: row;      
      flex-wrap: wrap;
      justify-content: space-between;
      .left {
        .title {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 15px;
          /* identical to box height */
          color: rgba(0, 0, 0, 0.51);
        }
        .description {
          margin-top: 0.5rem;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 0.9rem;
          line-height: 18px;
          /* identical to box height */
          color: var(--primary);
        }
      }
      .right {
        // p {
        //   background-color: var(--secondary);
        //   font-family: "Poppins";
        //   font-style: normal;
        //   font-weight: 400;
        //   font-size: 0.75rem;
        //   line-height: 15px;
          
        //   padding: 2px 5px;
        // }
      }
    }

    .lowerSection {
      margin: 0.5rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
    align-items: center;
        .title {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 18px;
          /* identical to box height */
          color: rgba(0, 0, 0, 0.51);
        }
        .description {
          margin-left: 1rem;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 0.9rem;
          line-height: 18px;
          /* identical to box height */
          color: var(--primary);
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        
        button {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 15px;
          background: #077dfa;
          color: #ffffff;
          /* background: var(--secondary); */
          padding: 3px 1rem;
          margin: 5px 0px;
          border-radius: 10px;
        //   &:hover{
        //   &:not(button):hover{
        //     background: #2b91ff;
        //   }
        // }          
        }
        &:hover {
          button:not(:hover){
          background: #2b91ff;
        }}
      }
      
    }
  }
  .deletingCard{
    animation: zoom forwards 1s ease-out 1;
  }
  .editingCard{
    // animation: zoom forwards 1s ease-out 1;
    transform: rotateY(90deg);
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(400px);
    animation-timing-function: ease-out;
  }
  60% {
    transform: translateY(-30px);
    animation-timing-function: ease-in;
  }
  80% {
    transform: translateY(10px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}  

  
  .totalCostPopup {
    animation: slideIn 1s linear;
    background: #fff;
    // position: fixed;
    bottom: 0px;
    z-index: 1;
    border: 1px solid green;
    border-radius: 33px 33px 0px 0px;
    padding: 15px 15px;
    // box-shadow: 1rem 15rem 1rem 44rem rgb(0 0 0 / 25%);
    
    @media (min-width: 768px) {
      // bottom: 27%;
      // left: 30%;
      width: 768px;
      border-radius: 33px 33px 33px 33px;
      padding: 2rem;
      // animation: zoomout 1s;
    }
    
    @media (max-width: 768px) {
      position: fixed;
      width: 100%;
      table{
        width: 100%;
      }
    }
  }
  .totalCostPopup .lineGreen {
    margin: 0 auto;
    padding: 15px 0px;
  }
  .totalCostPopup .title {
    font-weight: 600;
    font-size: 1rem;
    color: var(--primary);
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .totalCostPopup table thead {
    border-top: 0.7px solid rgba(0, 0, 0, 0.23);
    border-bottom: 0.7px solid rgba(0, 0, 0, 0.23);
  }
  .totalCostPopup table thead tr th {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.37);
    text-align: right;
  }
  .totalCostPopup table thead tr th:nth-of-type(1) {
    text-align: left;
  }
  .totalCostPopup table tbody tr td {
    padding: 10px 0;
  }
  .totalCostPopup table tbody tr:nth-last-child(1) {
    border-top: 0.7px solid rgba(0, 0, 0, 0.23);
    border-bottom: 0.7px solid rgba(0, 0, 0, 0.23);
  }
  .totalCostPopup table tbody tr td {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 18px;
    color: var(--primary);
    text-align: right;
  }
  .totalCostPopup table tbody tr td:nth-of-type(1) {
    text-align: left;
  }
  .totalCostPopup table tbody tr:nth-last-child(1) td {
    font-weight: 600;
    font-size: 1rem;
    line-height: 30px;
    color: var(--primary);
  }
  .totalCostPopup .total {
    padding-top: 50px;
  }
  .totalCostPopup .total .totalOuter .totalText {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.37);
  }
  .totalCostPopup .total .totalOuter .include {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.65);
    padding-top: 5px;
    font-style: italic;
  }
  .totalCostPopup .total .totalOuter .include .total {
    font-weight: 600;
    font-size: 1rem;
    line-height: 30px;
    color: var(--primary);
    // padding-right: 10px;
  }
  .totalCostPopup .total .backBtn {
    background: var(--secondary);
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    color: var(--primary);
    text-transform: uppercase;
    width: 50%;
    padding: 10px;
  }
  @media (min-width: 768px) {
    .popupWrapper{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@keyframes zoom { 
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.25);
    opacity: 0.5;
  }  
}
@keyframes zoomout { 
  0% {
    transform: scale(0.5);
    width: 80%;
    min-width: 80%;
    height: auto;
    opacity: 1;
    transform-origin: 50% 50%;
  }
  // 50% {
  //   // width: 90%;
  //   // min-width: 90%;
  //   height: auto;
  //   max-width: 768px;
  //   transform: scale(0.75);
  //   opacity: 1;
  // }
  100% {
    width: 768px;
    min-width: 768px;
    max-width: 768px;
    height: auto;
    transform: scale(1);
    opacity: 1;
    padding: auto;
    margin: auto;
  }  
}

.linksOuter {
  // padding: 30px 0px;
  display: flex;
  justify-content: flex-end;

  .addLink {
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 18px;
    text-decoration-line: underline;
    color: var(--primary);
    cursor: pointer;
  }
  .viewLink {
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 18px;
    text-decoration-line: underline;
    color: var(--primary);
    cursor: pointer;
  }
}


.bundleFinalEnroll-new{
  .main{
    .single-card{
      margin: 1rem;
      background: var(--tertiary);
      // width: 48%;
      padding: 0.75rem;
      transition: ease-in-out 1s;
      display: flex;
      flex-direction: column;   
      .dep-info{
        display: flex;
        flex-direction: row;      
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ffffff;
        .row1{
          width: 25%;
          .dep-rel{
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 15px;
            /* identical to box height */
            color: rgba(0, 0, 0, 0.51);
          }
          .dep-name{
            margin-top: 0.5rem;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 0.9rem;
              line-height: 18px;
              /* identical to box height */
              color: var(--primary);
          }
        }
      }
      .row2{
        display: flex;
        flex-direction: row;      
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 1rem;
        .coverage{
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 15px;
          /* identical to box height */
          color: rgba(0, 0, 0, 0.51);
          span{
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 0.9rem;
            line-height: 18px;
            /* identical to box height */
            color: var(--primary);
          }
        }
        .btn{
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 15px;
          background: #077dfa;
          color: #ffffff;
          /* background: var(--secondary); */
          padding: 3px 1rem;
          margin: 5px 0px;
          border-radius: 10px;
          cursor: pointer;
        }

      }

    }
  }
}