.ben-modal{
    .lineGreen {
        margin: 0 auto;
        padding: 15px 0px;
    }
    .container{
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.65);
        a{
            color: #00a0e1;
        }
    }       
}