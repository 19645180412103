.addBeneficiaryPage{
    .data {
      padding: 30px 1rem;
      .bottomButtonReq {
        padding: 0px;
      }
      .PageTitle-And-Description {
        margin-left: 0;
      }
        
      .radioButtonOuter {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .radioButtonOuter .radioButton {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: var(--tertiary);
        padding: 15px 15px;
        margin: 0.5rem 0;
        .boxAndName{
          display: inline-flex;
          font-style: normal;
          font-weight: 400;
          font-size: 0.95rem;
          line-height: 18px;
          align-items: center;
          width: 100%;
          .subTitle {
            font-style: normal;
            font-weight: 400;
            font-size: 0.625rem;
            line-height: 15px;
        }
        }
        
        
      .eclipseOuter {
        position: relative;
    }
      .eclipseOuter .count {
        position: absolute;
        left: 0;
        right: 0;
        top: 18px;
        font-size: 0.5rem;
        margin: 0 auto;
        text-align: center;
        font-weight: 400;
        background-color: whitesmoke;
    width: 1.5rem;
    }
      }
      .radioButtonOuter .radioButton .radioOuter {
        position: relative;
        margin-right: 15px;
      }
      .radioButtonOuter .radioOuter .radioCheck {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 7px;
        opacity: 0;
      }
    
      .radioButtonOuter .radioButton.active .radioCheck {
        opacity: 1;
      }
      .radioButtonOuter .radioButton.active {
        background: var(--primary);
        color: #fff;
        .count{
          background-color: var(--primary);
          color: #fff;
          width: 1.5rem;
        }
      }
    
    }
    .linksOuter {
      // padding: 30px 0px;
      display: flex;
      justify-content: flex-start;

      .addLink {
        font-style: normal;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 18px;
        text-decoration-line: underline;
        color: var(--primary);
        cursor: pointer;
      }
    }

    @keyframes slideIn {
      0% {
        transform: translateY(400px);
        animation-timing-function: ease-out;
      }
      100% {
        transform: translateY(0px);
        animation-timing-function: ease-in;
      }
    }
    
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }  

    .totalCostPopup {
      animation: slideIn 1s linear;
      background: #fff;
      position: fixed;
      bottom: 0px;
      z-index: 1;
      border: 1px solid green;
      border-radius: 33px 33px 0px 0px;
      padding: 15px 15px;
      // box-shadow: 1rem 15rem 1rem 44rem rgb(0 0 0 / 25%);
      @media (min-width: 768px) {
        bottom: 27%;
        left: 30%;
        border-radius: 33px 33px 33px 33px;
        padding: 2rem;
      }
    }
    .totalCostPopup .lineGreen {
      margin: 0 auto;
      padding: 15px 0px;
    }
    .totalCostPopup .title {
      font-weight: 600;
      font-size: 1rem;
      color: var(--primary);
      padding-top: 20px;
      padding-bottom: 15px;
    }
    .totalCostPopup table thead {
      border-top: 0.7px solid rgba(0, 0, 0, 0.23);
      border-bottom: 0.7px solid rgba(0, 0, 0, 0.23);
    }
    .totalCostPopup table thead tr th {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.37);
    }
    .totalCostPopup table tbody tr td {
      padding: 10px 0;
    }
    .totalCostPopup table tbody tr:nth-last-child(1) {
      border-top: 0.7px solid rgba(0, 0, 0, 0.23);
      border-bottom: 0.7px solid rgba(0, 0, 0, 0.23);
    }
    .totalCostPopup table tbody tr td {
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 18px;
      color: var(--primary);
    }
    .totalCostPopup table tbody tr:nth-last-child(1) td {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 30px;
      color: var(--primary);
    }
    .totalCostPopup .total {
      padding-top: 50px;
    }
    .totalCostPopup .total .totalOuter .totalText {
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.37);
    }
    .totalCostPopup .total .totalOuter .include {
      font-weight: 400;
      font-size: 0.625rem;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.37);
    }
    .totalCostPopup .total .totalOuter .include .total {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 30px;
      color: var(--primary);
      padding-right: 10px;
    }
    .totalCostPopup .total .backBtn {
      background: var(--secondary);
      font-weight: 600;
      font-size: 1rem;
      line-height: 24px;
      color: var(--primary);
      text-transform: uppercase;
      width: 50%;
    }
    .MuiButton-outlined{
      letter-spacing: 0px;
    }
    
}