.my-beneficiaries-main{
    margin: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    color: var(--primary);
  .my-beneficiaries-plan{
    .plan-block{
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: var(--tertiary);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      .info-block{        
        .plan-name{
          font-weight: 600;
        }
        .class-name{
          font-size: 0.79rem;
          opacity: 0.5;
        }
      }
      .action-block{
          .add-allocate-btn{
            background-color: var(--secondary);
            padding: 0.25rem 0.5rem;
            border-radius: 0.4rem;
            font-size: 0.81rem;
            &:hover{
                opacity: 0.9;
            }
            @media(max-width:"500px"){
                font-size: 0.7rem;
            }
          }
      }
    }
  }
}





