.footer {
    background: var(--footerBackground);
    position: fixed;
    width: 100%;
    bottom: 0px;
    padding: 10px 0;
    z-index: 10;
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;

    .menu {
        display: flex;
        justify-content: space-between;

        p{font-size: 0.75rem;
            text-align: center;
            width: 100%;
            cursor: pointer;
            color: var(--primary);
        .menuLink {
            font-size: 0.75rem;
            text-align: center;
            width: 100%;
            cursor: pointer;
            color: var(--primary);
            .menuImg {
                display: flex;
                justify-content: center;
                position: relative;
                min-height: 30px;

                .menuActive {
                    position: absolute;
                    top: -35px;
                    opacity: 0;
                }
            }
         }   
        }
        .menuLink {
            font-size: 0.75rem;
            text-align: center;
            width: 100%;
            cursor: pointer;
            color: var(--primary);
            .menuImg {
                display: flex;
                justify-content: center;
                position: relative;
                min-height: 30px;

                .menuActive {
                    position: absolute;
                    top: -35px;
                    opacity: 0;
                }
            }
         }   
        
        .menuLink.active .menuImg .menuActive {
            opacity: 1;
        }
        .menuLink.active .img {
            position: absolute;
            z-index: 1;
            top: -17px;
        }
    
    }
    }

