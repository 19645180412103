.PageTitle-And-Description{

    .details {
        .title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        color: var(--primary);
    }
    .subTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 0.9rem;
        color: rgba(0, 0, 0, 0.37);
        padding: 15px 0;
    }
    }

}
