#notification_btn {
  position: relative;
}
#notificationContainer {
  background-color: #fff;
  border: 1px solid rgba(100, 100, 100, 0.4);
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
  overflow: scroll;
  position: absolute;
  top: 48px;
  right: 10px;
  padding-inline: 16px;
  margin-inline: auto;
  width: 400px;
  height: 60vh;
  z-index: -1;
  display: block; // Enable this after jquery implementation
}
// Popup Arrow
#notificationContainer:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  color: transparent;
  border: 10px solid black;
  border-color: transparent transparent white;
  margin-top: -20px;
  margin-left: 188px;
  // margin-left: 326px;
}
.notfCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
#notificationTitle {
  font-weight: bold;
  padding-top: 8px;
  font-size: 13px;
  background-color: #ffffff;
  // position: fixed;
  z-index: 1000;
  // border-bottom: 1px solid #dddddd;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#notificationsBody {
  padding: 16px 0px 0px 0px !important;
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.notfDayCont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  min-height: 17px;
  p {
    font-size: 13px;
    color: #7c879a;
  }
}
.notfDayCont p:hover {
  text-decoration: underline;
}
.notfCard {
  display: flex;
  align-items: center;
  // justify-content: space-between;
}
.notfAvtrImg {
  // display: inline-block;
  // position: relative;
  margin-right: 8px;

  img {
    width: 40px;
    height: 40px;
  }
  flex: 0.12;
}
.contntWrapper {
  display: flex;
  flex-direction: column;
}
.notfCardTxt {
  flex: 0.88;
  font-size: 12px;
  text-align: left;
  .titleOfNtf {
    font-weight: bold;
  }
  .msgOfNtf {
    font-weight: 300;
    span {
      color: #6b778c;
      font-weight: 400;
      white-space: nowrap;
      margin-left: 4px;
    }
  }
}
.notfCardAddnlContnt {
  display: flex;
  align-items: center;
  .blnk {
    flex: 0.12;
    margin-right: 8px;
  }
  .cntntArea {
    margin-top: 12px;
    margin-bottom: 8px;
    flex: 0.88;
    display: flex;
    img {
      width: 16px;
      height: 16px;
      flex: 0.12;
    }
    .notfCardAddnlTxt {
      margin-left: 4px;
      color: #0052cc;
      font-size: 12px;
      text-align: left;
      flex: 0.88;
    }
  }
}

.titleText {
  font-size: 1.71429em;
  font-family: "poppins";

  line-height: 1.16667;
  color: var(--primary);
  font-weight: 500;
  letter-spacing: -0.01em;
}
.osuDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notfCardCont {
  padding-bottom: 8px;
}

.osuText {
  font-size: 12px;
  line-height: 20px;
  margin-right: 4px;
  font-weight: 400;
  color: #42526e;
}

@media (max-width: 768px) {
  #notificationContainer {
    width: 80vw;
  }
  #notificationTitle {
    font-size: 10px;
  }
  .osuText {
    font-size: 8px;
  }
}
