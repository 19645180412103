.leave-modals-block{
    // min-height: 240px;
    padding: 0.5rem 0.4rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.75rem;
    list-style-type: disc;
    table{
        margin: 1rem 0px;
        width: 100%;
    }
    th{
        background: var(--secondary);
        padding: 0.25rem 0.5rem;
        width: 33.33%;
    }
    td{
        background: var(--tertiary);
        padding: 0.25rem 0.5rem;
        vertical-align: text-top;

    }

    .paracumdescription{

        margin: 1rem 0px;

    }

}

.leave-modals-block-coverage{
    // min-height: 240px;
    padding: 0.5rem 0.4rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.75rem;
    list-style-type: disc;
    table{
        margin: 1rem 0px;
        width: 100%;
        border: 1px solid #000000;
    }
    .serialNumber{
        background: var(--secondary);
        padding: 0.25rem 0.5rem;
        width: 10%;
    }
    th{
        background: var(--secondary);
        padding: 0.25rem 0.5rem;
        width: 22.5%;
        border: 1px solid #000000;
    }
    td{
        background: var(--tertiary);
        padding: 0.25rem 0.5rem;
        vertical-align: text-top;
        border: 1px solid #000000;

    }

    .paracumdescription{

        margin: 1rem 0px;

    }

}
.leave-modals-block-coverage-emergency{
    // min-height: 240px;
    padding: 0.5rem 0.4rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.75rem;
    list-style-type: disc;
    table{
        margin: 1rem 0px;
        width: 100%;
    }
    .coverages{
        background: var(--secondary);
        padding: 0.25rem 0.5rem;
        width: 20%;
    }
    th{
        background: var(--secondary);
        padding: 0.25rem 0.5rem;
        width: 80%;
    }
    td{
        background: var(--tertiary);
        padding: 0.25rem 0.5rem;
        vertical-align: text-top;

    }

    .paracumdescription{

        margin: 1rem 0px;

    }

}