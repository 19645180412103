.calender-main-page{
    margin: 1rem;

    .app-calendar,
    .rbc-calendar {
        font-family: 'Poppins', serif;
        width: 100%;
    }

    .app-calendar .rbc-calendar {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 100%;
        min-height: calc(100vh - 360px);
    }

    .app-cul-calendar .rbc-calendar {
        min-height: calc(100vh - 400px);
    }

    .rbc-toolbar button:active,
    .rbc-toolbar button.rbc-active {
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        -o-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }

    .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
        border: solid 1px #e5e9ec;
    }

    .rbc-month-row {
        min-height: 5rem;
    }

    .rbc-event {
        min-height: 1rem;
    }

    .rbc-event:focus {
        outline: none;
    }

    .hs-calendar .react-daypicker-root {
        border: solid 1px #e5e9ec;
        border-radius: 30px;
        padding: 30px;
        width: 100%;
        height: 100%;
    }

    .hs-calendar table {
        width: 100%;
        height: calc(100% - 30px);
    }

    .hs-calendar table td {
        position: relative;
    }

    .hs-calendar table th {
        text-transform: uppercase;
        font-weight: var(--light);
        font-size: 15px;
    }

    .hs-calendar .header .month-year {
        font-weight: var(--extra-bold);
        padding-top: 3px;
    }

    .hs-calendar-color .react-daypicker-root {
        border: 0 none;
        font-size: 20px;
    }

    .hs-calendar-color .header {
        padding: 30px;
        margin: -30px -30px 0;
    }

    .hs-calendar-color .header .month-year {
        font-size: 22px;
    }

    .hs-calendar-color table {
        font-weight: var(--extra-bold);
        height: calc(100% - 60px);
    }

    .hs-calendar-color table thead th {
        font-weight: var(--extra-bold);
        font-size: 20px;
    }

    // added in employee
    .rbc-button-link{
        font-size: 0.75rem;
        font-weight: 600;
    }
    .rbc-event-content{
        font-size: 0.75rem;
    }
    .rbc-show-more{
        font-size: 0.7rem;
        word-break: break-all;
    }
    .rbc-btn-group{
        margin: 1rem auto;
    }
    .rbc-overlay{
        .rbc-overlay-header{
        font-size: 0.9rem;
    }
    }
    .rbc-header{
        .columnheader{
            font-weight: 500;
        }
    }
    .add-event-btn{
        margin: 1rem;
        display: flex;
        justify-content: flex-end;
    }
    .delete-confirm-popup{
        position: absolute;
        top: 41%;
        left: 20%;
        background-color: #ffffff;
        padding: 2rem;
        border-radius: 1rem;
        border: 1px solid #000000;
        z-index: 1001;
        box-shadow: 1rem 15rem 1rem 44rem rgb(0 0 0 / 25%);
    }

    .event-delete-popup-btns{
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        .yes-btn{
            padding: 0.25rem 1rem;
            background-color: var(--primary);
            color: var(--secondary);
            margin-right: 1rem;
        }
        .no-btn{
            padding: 0.25rem 1rem;
            color: var(--primary);
            background-color: var(--secondary);
        }
    }
}

.popup-btns{
    display: flex;
    justify-content: flex-end;
    button{
        font-family: 'poppins';
    }
}
.calender-popup-block{
    .event-info-row{
        margin: 1rem 0px;
    }
}

input{
    font-family: 'poppins' !important;
}