.longTermDisabilityPage{
    .data {
      padding: 30px 1rem;
      .bottomButtonReq {
        padding: 0px;
      }
      .PageTitle-And-Description {
        margin-left: 0;
      }
        
      .radioButtonOuter {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .radioButtonOuter .radioButton {
        display: inline-flex;
        background: var(--tertiary);
        padding: 15px 15px;
        margin: 0.5rem 0;
        font-style: normal;
        font-weight: 400;
        font-size: 0.95rem;
        line-height: 18px;
        color: var(--primary);
        align-items: center;
        width: 100%;
      }
      .radioButtonOuter .radioButton .radioOuter {
        position: relative;
      	margin-right: 15px;
      }
      .radioButtonOuter .radioOuter .radioCheck {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 7px;
        opacity: 0;
      }
    
      .radioButtonOuter .radioButton.active .radioCheck {
        opacity: 1;
      }
      .radioButtonOuter .radioButton.active {
        background: var(--primary);
        color: #fff;
      }
    
    }
}