.lifeInsurancePage {
  .data {
    padding: 30px 1rem;
    .bottomButtonReq {
      padding: 0px;
    }
    .PageTitle-And-Description {
      margin-left: 0;
    }

    .radioButtonOuter {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      .coverage-selected {
        color: var(--primary);
        background-color: var(--tertiary);
        padding: 0.7rem 2rem;
      }
      .css-14pt78w-MuiSlider-rail {
        color: #a5a5a5;
      }
      .css-1gv0vcd-MuiSlider-track {
        color: var(--primary);
      }
      .css-eg0mwd-MuiSlider-thumb {
        color: var(--primary);
      }
      .css-nnid7-MuiSlider-valueLabel {
        color: var(--tertiary);
        background-color: var(--primary);
      }
      .MuiSlider-markLabel {
        color: var(--primary);
        font-weight: 600;
      }
    }
    .radioButtonOuter .radioButton {
      display: inline-flex;
      background: var(--tertiary);
      padding: 15px 15px;
      margin: 0.5rem 0;
      font-style: normal;
      font-weight: 400;
      font-size: 0.95rem;
      line-height: 18px;
      color: var(--primary);
      align-items: center;
      width: 100%;

      background: linear-gradient(
          to left,
          var(--tertiary) 50%,
          var(--primary) 50%
        )
        right;
      background-size: 200%;
      transition: 0.25s ease-out;
      background-position: right;
    }
    .radioButtonOuter .radioButton .radioOuter {
      position: relative;
      margin-right: 15px;
    }
    .radioButtonOuter .radioOuter .radioCheck {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 7px;
      opacity: 0;
    }

    .radioButtonOuter .radioButton.active .radioCheck {
      opacity: 1;
    }
    .radioButtonOuter .radioButton.active {
      // background: var(--primary);
      color: #fff;

      background: linear-gradient(
          to left,
          var(--tertiary) 50%,
          var(--primary) 50%
        )
        right;
      background-size: 200%;
      transition: 0.25s ease-out;
      background-position: left;
    }
  }
  .linksOuter {
    // padding: 30px 0px;
    display: flex;
    justify-content: flex-end;

    .viewLink {
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 18px;
      text-decoration-line: underline;
      color: var(--primary);
      cursor: pointer;
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateY(400px);
      animation-timing-function: ease-out;
    }
    100% {
      transform: translateY(0px);
      animation-timing-function: ease-in;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .totalCostPopup {
    animation: slideIn 1s linear;
    background: #fff;
    bottom: 0px;
    z-index: 1;
    border: 1px solid green;
    border-radius: 33px 33px 0px 0px;
    padding: 15px 15px;
    @media (min-width: 768px) {
      width: 768px;
      border-radius: 33px 33px 33px 33px;
      padding: 2rem;
    }

    @media (max-width: 768px) {
      position: fixed;
      width: 100%;
      table {
        width: 100%;
      }
    }
  }
  .totalCostPopup .lineGreen {
    margin: 0 auto;
    padding: 15px 0px;
  }
  .totalCostPopup .title {
    font-weight: 600;
    font-size: 1rem;
    color: var(--primary);
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .totalCostPopup table thead {
    border-top: 0.7px solid rgba(0, 0, 0, 0.23);
    border-bottom: 0.7px solid rgba(0, 0, 0, 0.23);
  }
  .totalCostPopup table thead tr th {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.37);
    text-align: right;
  }
  .totalCostPopup table thead tr th:nth-of-type(1) {
    text-align: left;
  }
  .totalCostPopup table tbody tr td {
    padding: 10px 0;
  }
  .totalCostPopup table tbody tr:nth-last-child(1) {
    border-top: 0.7px solid rgba(0, 0, 0, 0.23);
    border-bottom: 0.7px solid rgba(0, 0, 0, 0.23);
  }
  .totalCostPopup table tbody tr td {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 18px;
    color: var(--primary);
    text-align: right;
  }
  .totalCostPopup table tbody tr td:nth-of-type(1) {
    text-align: left;
  }
  .totalCostPopup table tbody tr:nth-last-child(1) td {
    font-weight: 600;
    font-size: 1rem;
    line-height: 30px;
    color: var(--primary);
  }
  .totalCostPopup .total {
    padding-top: 50px;
  }
  .totalCostPopup .total .totalOuter .totalText {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.37);
  }
  .totalCostPopup .total .totalOuter .include {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.65);
    padding-top: 5px;
    font-style: italic;
  }
  .totalCostPopup .total .totalOuter .include .total {
    font-weight: 600;
    font-size: 1rem;
    line-height: 30px;
    color: var(--primary);
  }
  .totalCostPopup .total .backBtn {
    background: var(--secondary);
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    color: var(--primary);
    text-transform: uppercase;
    width: 50%;
    padding: 10px;
  }
  .pension-plan {
    padding-top: 0rem;
    .MuiInputBase-adornedEnd {
      div {
        p {
          background-color: #eaeaea;
          width: 4rem;
          margin: 0 auto;
          padding: 15px;
          margin-right: -14px;
          font-size: 1rem;
          font-weight: 900;
          display: flex;
          justify-content: center;
        }
      }
    }
    .togglaeMain {
      display: flex;
      justify-content: flex-end;
      .darkText {
        color: var(--primary);
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 600;
      }
      .lightText {
        color: var(--tertiary);
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 600;
      }
      .switch-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 51px;
        height: 21px;
        background-color: var(--primary);
        border-radius: 15px;
        padding: 5px;
        cursor: pointer;
        margin: 0px 0.5rem;
      }

      .switch-button-label {
        font-size: 14px;
        color: #666;
      }

      .switch-button-circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
      }

      .switch-button.on .switch-button-circle {
        transform: translateX(27px);
      }

      .switch-button.off .switch-button-circle {
        transform: translateX(0px);
      }
    }
    .pension-card {
      padding: 1rem;
      margin: 0.5rem 1rem;
      box-shadow: 6px 6px 20px 6px #f5f7f7;
      border-radius: 10px;
      @media (max-width: 500px) {
        margin: 0;
        margin-top: 1rem;
      }
      .first-section {
        .que-pension {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.1rem;
          color: #000000;
        }
        .contri-header {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.25rem;
          color: var(--primary);
        }
      }
      .pension-contri-slider {
        padding: 2rem 2rem;
        padding-bottom: 0.5rem;
      }
      .slabTags {
        width: 100%;
        margin-bottom: 1rem;
        padding: 0px 1rem;
        @media (max-width: 500px) {
          padding: 0px;
        }
        .slabBlocks{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
        }
        span {
          cursor: pointer;
          background-color: var(--tertiary);
          padding: 0.25rem 1rem;
          border-radius: 5px;

          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1rem;
          color: #000000;

          @media (max-width: 500px) {
            padding: 0.25rem 0.5rem;
          }
        }
      }
      .MuiSlider-mark{
        width: 4px;
        height: 4px;
      }
    }
    .employerMatch {
      margin: 1rem 1rem;
      @media (max-width: 500px) {
        margin: 1rem;
      }
      .empmatch{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .upper {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.1rem;
          color: #000000;
        }
        svg{
          font-size: 1rem;
        }
        .middle {
          width: 10rem;
          button{
            margin: 0.5rem 0px;
            padding: 0;
          }        
        }
        .full-middle {
          button {
            margin: 0.5rem 0px;
            padding: 0;
            p {
              text-align: left;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 1rem !important;
              line-height: 1.25rem !important;
              color: var(--primary);
            }
          }
        }
      }
      
      .bottom {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.1rem;
        color: #000000;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        margin-bottom: 1rem;
        svg{
          font-size: 1rem;
        }
        .tAmount{
          font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        color: var(--primary);
        }
      }
    }
    .enterId{
      margin: 0px 1rem;
    }
  }
  .AddBeneficiaryMainPage{
    width:100%;
  }
}

@media (min-width: 768px) {
  .popupWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #929292;
}
