.BMheader {
  background-color: var(--secondary);
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;

  .pageheaderdata {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }
  .pageheaderdatadata2 {
    padding: 0px 10px 30px 15px;
  }
  .pageheaderdata .pageheaderDataInneer .title {
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
  }
  .pageheaderdata .pageheaderDataInneer .desc {
    font-weight: 500;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.37);
    padding: 15px 0px;
  }
  .pageheaderdatadata2 .infoContainerDiv {
   .moreDetails {
    background: var(--tertiary);
    padding: 15px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 15px;
    color: var(--primary);
    @media(max-width:500px){
      padding: 12px 10px;
    }
   }
    svg{
      color: var(--tertiary);
      background-color: var(--primary);
      border-radius: 1rem;
      font-size: 1.5rem;
      margin-left: 0.5rem;
      cursor: pointer;
      @media(max-width:500px){
        font-size: 1.5rem;
        margin-left: 0.25rem;
      }
    }
  }
  .pageheaderdata .medicalImage {
    padding-top: 10px;
  }
  .pageheaderdatadata2 .eclipseOuter {
    position: relative;
    margin-right: 11px;
    display: flex;
    align-items: center;
    .righttovarybtn{
      font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 18px;
        color: var(--primary);
        cursor: pointer;
        text-decoration: underline;
    }
  }
  .pageheaderdatadata2 .eclipseOuter .count {
    position: absolute;
    left: 0;
    right: 0;
    top: 18px;
    font-size: 0.625rem;
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
  }
  .medicalImage {
    padding-top: 10px;
  }
  .name {
    font-weight: 500;
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.71);
    padding-bottom: 5px;
  }
  .date {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.37);
    padding-left: 10px;
  }
}
