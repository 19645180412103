.reimbursement {
  .mainTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    color: var(--primary);
  }
  .cardOuter {
    .card {
      background: #fafafa;
      height: 8rem;
      padding: 30px 0px;
      text-align: center;
      position: relative;
      cursor: pointer;
      // display: flex;
      // justify-content: center;
      // vertical-align: middle;
      .imageOuter {
        .cardImg {
          margin: 0 auto;
          display: block;
        }
        .cardImg.active {
          display: none;
        }
      }
      .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 14px;
        color: var(--primary);
      }
      .activeImg {
        opacity: 0;
      }
    }
    .card.active {
      background: var(--primary);
      .imageOuter {
        .cardImg {
          display: none;
        }
        .cardImg.active {
          display: block;
        }
      }
      .title {
        color: #fff;
      }
      .activeImg {
        opacity: 1;
        position: absolute;
        top: 10px;
        right: 11px;
      }
    }
  }
  .bannerTextOuter {
    padding: 25px 1rem;
    background: rgba(250, 243, 227, 0.65);
    .bannerText {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.37);
    }
  }

  .radioButtonOuter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .radioButtonOuter .radioButton {
    display: inline-flex;
    background: var(--tertiary);
    padding: 15px 15px;
    margin: 0.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 15px;
    color: var(--primary);
    align-items: center;
    width: 48%;
    // background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
    background-size: 200%;
    transition: 0.25s ease-out;
    background-position: right;
  }
  .radioButtonOuter .radioButton .radioOuter {
    position: relative;
    margin-right: 5px;
    min-width: 1.7rem;
  }
  .radioButtonOuter .radioOuter .radioCheck {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 7px;
    opacity: 0;
  }

  .radioButtonOuter .radioButton.active .radioCheck {
    opacity: 1;
  }
  .radioButtonOuter .radioOuter svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 4px;
    opacity: 1;
  }

  .radioButtonOuter .radioButton.active {
    background: var(--primary);
    color: #fff;
    background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
    background-size: 200%;
    transition: 0.25s ease-out;
    background-position: left;
  }
  .claimBlock {
    text-align: end;
    .claimTitle {
      text-align: end;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 24px;
      cursor: pointer;

      color: #000000;
    }
  }

  .description {
    background-color: rgba(250, 243, 227, 0.65);
    padding: 1rem;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 0.82rem;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.37);
  }

  .questions {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    /* identical to box height */
    color: var(--primary);
  }

  //   .expenseSpendDiv{
  //     margin-top: 1rem;
  //     display: flex;
  //     justify-content: space-between;

  //     .childBlock{
  //       width: 31%;
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       text-align: center;
  //       align-items: center;
  //       min-height: 7rem;
  //       cursor: pointer;
  //       position: relative;

  //       background: #FAFAFA;
  //       font-family: 'Poppins';
  //       font-style: normal;
  //       font-weight: 400;
  //       font-size: 0.82rem;
  //       line-height: 18px;
  //       /* identical to box height */
  //       color: var(--primary);
  //       .cardImg{
  //         width: 24px;
  //         // height: 34px;
  //         margin: 0.5rem 0px;
  //       }
  //       .activeImg {
  //         opacity: 0;
  //       }

  // }
  // .childBlock.active{
  //   background: var(--primary);
  //   color: #ffffff;
  //   .cardImg{
  //     filter: invert(1);
  //   }

  // .activeImg {
  //     opacity: 1;
  //     position: absolute;
  //     top: 10px;
  //     right: 11px;
  //   }
  // }
  // .childBlock.addOption{
  //    background: #ffffff;
  //    border: 1px solid var(--primary);
  //    border-style: dashed;
  // }
  // }
    .expenseSpendDivD{
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;

      .childBlock{
        width: 31%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        min-height: 7rem;
        cursor: pointer;
        position: relative;

        background: #FAFAFA;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.82rem;
        line-height: 18px;
        /* identical to box height */
        color: var(--primary);
        .cardImg{
          width: 24px;
          // height: 34px;
          margin: 0.5rem 0px;
        }
        .activeImg {
          opacity: 0;
        }

  }
  .childBlock.active{
    background: var(--primary);
    color: #ffffff;
    .cardImg{
      filter: invert(1);
    }

  .activeImg {
      opacity: 1;
      position: absolute;
      top: 10px;
      right: 11px;
    }
  }
  .childBlock.addOption{
     background: #ffffff;
     border: 1px solid var(--primary);
     border-style: dashed;
  }
  }
}