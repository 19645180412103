.learningPage {
    .heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 18px;
        background: var(--tertiary);
        color: var(--primary);
        padding: 2rem 20px;
        // margin: 20px 1rem;
    }

    .block1 {
        text-align: center;
        padding: 1rem;
        .block-div {
            // margin: 1rem;
            background: var(--tertiary);
            text-align: -webkit-center;
            position: relative;
            .block-image {
                width: 34px;
                // height: 34px;
                margin: 20px;
            }

            .block-text {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 14px;
                text-align: center;
                color: var(--primary);
                margin-bottom: 20px !important;
                padding: 0px 11px;
            }
        }
    }

    .content {
        background: var(--secondary);
        padding: 20px;

        .content-description {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.37);
            text-align: justify;
            p{
              margin-top: 0.25rem;
              margin-bottom: 0.25rem;
            }
        }
    }

    .block2 {
        .block-div {
            padding-bottom: 10px;

            .block2-image-div {
                display: flex;
                justify-content: center;
                position: relative;
                .icon-image {
                    margin-top: -12px;
                    margin-right: 7px;
                }
            }

            .block2-text {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 14px;
                text-align: center;
                color: var(--primary);
                padding-bottom: 5px;
            }

            .block2-sub_text {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 0.75rem;
                text-align: center;
                line-height: 14px;
                color: rgba(0, 0, 0, 0.37);
                padding-bottom: 5px;
            }

            .block2-button {
                width: 150px;
                height: 30px;
                left: 25px;
                top: 506px;
                // background: var(--secondary);
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 12px;
                text-align: center;
                color: var(--primary)d6;
            }
        }
    }
}

.learningPageCoverageDetails {
  .dependent-table {
    .table-header {
        background: var(--secondary);
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 17px;
        color: var(--primary);
        padding: 16px 0px;
    }

    td{
      background: var(--tertiary);
    }

    .table-rows {
      background: var(--tertiary);
      // min-height: 240px;
      padding: 0.5rem 0.4rem;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 0.75rem;
      list-style-type: disc;
      // padding-inline-start: 30px;
      // height: 55px;
    }
    .col-1 {
        color: var(--primary) !important;
    }
  }
  .dep-info-container{
    .content{
    background:#fff;
    .dep-info-row{
      p{
        margin-bottom: 1rem;
        font-family: "poppins";
        font-size: 1rem;
        span:first-child{
          font-weight: 600;
        }
      }
    }
  }
  .backBtn{
    width: 100%;
    margin: 0px auto;
  }
}
}

.learningPageCoverageDetails .learningPageDependents.leavesPopup .dependent-table .table-rows {
  min-height: 40px;
}
.learningPageCoverageDetails .learningPageDependents.leavesPopup .dependent-table .table-rows.height {
  height: 170px;
  display: flex;
  align-items: center;
}
.learningPageCoverageDetails .CoverageDetailsPopup .learningPageDependents.leavesPopup .description .disc {
  list-style-type: disc;
  padding-left: 30px;
  padding-inline-start: 15px;
}

.learningPageCoverageDetails .dependent-table th{
  background: var(--secondary);
}

@media (max-width: 767px) {
  .learningPageCoverageDetails .learningPageDependents.leavesPopup .dependent-table .table-rows.height {
    height: 215px;
  }
  .learningPageCoverageDetails .learningPageDependents.leavesPopup .dependent-table .table-rows {
    height: 70px;
    padding: 10px;
    margin: 0;
  }  

  .learningPageCoverageDetails .dependent-table .table-header {
    padding: 10px 0px;
    font-size: 0.9rem;
    margin: 0 !important;
  }

  .learningPageCoverageDetails .dependent-table .table-rows {
    // margin-bottom: 10px;
    // height: 420px;
  }
    .learningPage {
        .block1 .block-div {
            .block-text {
                font-size: 0.75rem;
                padding: 0px 11px;
            }
        }

        .content {
            .content-description {
                font-size: 0.75rem;
                p{
                  margin-top: 0.25rem;
                  margin-bottom: 0.25rem;
                }
            }
        }
    }

    .learningPage .block2 .block-div .block2-image-div {
        justify-content: space-between;
    }

    .learningPage .block2 {
        .block-div {
            text-align: -webkit-center;

            .block2-text {
                font-size: 0.9rem;
            }

            .block2-sub_text {
                font-size: 0.9rem;
            }
            .block2-button {
                width: 100%;
            }
        }
    }
}


@media (min-width: 768px) {
  .learningPageCoverageDetails {
    .dependent-table {
      .table-rows {
          padding: 10px;
          // padding-inline-start: 30px;
          // height: 240px;
          .para {
              margin-left: -1rem;
          }
      }
    }
  }
    .learningPage {
        .block1 {
            .block-div {
                .block-image {
                    width: 4rem;
                    height: 5rem;
                    margin: 20px 20px;
                    padding: 10px 0px;
                }
                .block-text {
                    position: absolute;
                    bottom: -10px;
                    left: 0;
                    right: 0;
                }
            }
        }
        .block2 {
            .block-div {
                .block2-image-div {
                    .icon-image {
                        margin-top: -12px;
                        margin-right: 7px;
                        position: absolute;
                        right: 40px;
                        top: 50px;
                    }
                }
            }
        }
    }
}

@media (min-width: 991px) {
  .learningPageCoverageDetails {
    .dependent-table {
      .table-rows {
        // height: 100px;
        padding-inline-start: 10px;
      }
    }
  }
  .learningPageCoverageDetails {
    .learningPageDependents.leavesPopup {
      .dependent-table {
        .table-rows {
          height: 55px;
        }
      }
    }
  }
}

.CoverageDetailsPopup{
  // animation: fadeIn 0.5s, slideIn .8s linear;
  animation: slideIn 0.41s linear;
}

@keyframes slideIn {
  0% {
    transform: translateY(400px);
    animation-timing-function: ease-out;
  }
  // 60% {
  //   transform: translateY(-30px);
  //   animation-timing-function: ease-in;
  // }
  // 80% {
  //   transform: translateY(10px);
  //   animation-timing-function: ease-out;
  // }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
