.contactUsPage{
    @media (min-width: 550px) {
    .cardWeb{
        display: flex;
        margin: 0px 1rem;
        margin-top: 2rem;
        padding: 1rem;
        background-color: var(--secondary);
        color: var(--primary);
        border: 1px solid var(--primary);

        .left{
            width: 12rem;
            font-size: 1.1rem;
            font-weight: 500;
            img{
                width: 10rem;
                border-radius: 5rem;
                margin: 0px auto;
                margin-bottom: 1rem;
                border: 2px solid #ffffff;
            }
            .designation{
                font-size: 0.9rem;

            }
        }
        .right{
            margin-left: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            border-left: 1px solid var(--primary);
            border-right: 1px solid var(--primary);
            .upper{
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 100%;
                p{
                    display: inherit;
                    svg{
                        margin: auto 0px;
                        font-size: 1.5rem;
                        color: var(--primary);
                    }
                }
            }
        }
        .nextToRight{
            max-width: 200px;
            margin-left: 1rem;
            .first{
                font-size: 0.75rem;
                .header-con{
                 font-size: 0.9rem;
                 font-weight: 600;
                }
            }
            .second{
                margin-top: 0.5rem;
                font-size: 0.75rem;
                .header-con{
                 font-size: 0.9rem;
                 font-weight: 600;
                }
            }
        }
    }
    .cardWeb-table{
        margin: 0px 1rem;
        margin-top: 1rem;
        background-color: var(--secondary);
        table, tr, td {
            border: 1px solid #000000;
        }
        td{
            width: 33.3%;
            padding: 0.25rem;
            font-size: 0.9rem;
            // p{
            //     display: flex;
            //     align-items: center;
            //     svg{
            //         margin: auto 0px;
            //         font-size: 1.5rem;
            //         color: var(--primary);
            //     }
            // }
        }
    }
}
    @media (max-width: 550px) {
        .cardWeb{
            display: flex;
            flex-direction: column;
            margin: 0px 1rem;
            margin-top: 2rem;
            padding: 1rem;
            background-color: var(--secondary);
            color: var(--primary);
            border: 1px solid var(--primary);
    
            .left{
                margin: 0px auto;
                font-size: 1.1rem;
                font-weight: 500;
                img{
                    width: 10rem;
                    border-radius: 5rem;
                    margin-bottom: 1rem;
                    border: 2px solid #ffffff;
                }
                .designation{
                    font-size: 0.9rem;
    
                }
            }
            .right{
                margin-top: 1rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                border-top: 1px solid var(--primary);
                border-bottom: 1px solid var(--primary);
                .upper{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    p{
                        margin: 0.5rem 0px;
                        display: inherit;
                        svg{
                            margin: auto 0px;
                            font-size: 1.5rem;
                            color: var(--primary);
                        }
                    }
                    
                }
            }
            .nextToRight{
                margin-top: 1rem;
            }
        }

    }
    .address{
            background-color: var(--primary);
            color: var(--secondary);
            margin: 0px 1rem;
            padding: 1rem;
    }

    @media(min-width: 500px){
        .contactProviderNiumIndiaMain{
            font-family: 'poppins';
            font-size: 0.82rem;
            
            .first-card{
                display: flex;
                flex-direction: row;
                border: 1px solid black;
                margin: 1rem;
                background-color: var(--secondary);
                color: var(--primary);
                .first-column{
                    width: 33.33%;
                    border-right: 1px solid;   
                    padding: 0.25rem;         
                    color: var(--secondary);
                    background-color: var(--primary);
                    .heading{
                        font-size: 0.95rem;
                        font-weight: 600;
                    }    
                }
                .second-column{
                    width: 33.33%;
                    padding: 0.25rem;
                    .heading{
                        font-size: 0.91rem;
                        font-weight: 600;
                    }  
                    .contact{
                        margin-top: 0.7rem;
                        span{
                            font-size: 0.75rem;
                            font-weight: 600;
                        }
                    }
                    .email{
                        margin-top: 0.7rem;
                        span{
                            font-size: 0.75rem;
                            font-weight: 600;
                        }
                        a{
                            color: blue;
                        }
                    }
                    .app-link{
                        margin-top: 0.7rem;
                        span{
                            font-size: 0.75rem;
                            font-weight: 600;
                        }
                        a{
                            color: blue;
                        }
                    }
                }
                .third-column{
                    width: 33.33%;
                    border-left: 1px solid; 
                    .first{
                        border-bottom: 1px solid;         
                        padding: 0.25rem;           
                        .heading{
                            font-size: 0.91rem;
                            font-weight: 600;
                        }  
                    }
                    .second{
                        padding: 0.25rem;
                        .heading{
                            font-size: 0.91rem;
                            font-weight: 600;
                        }  
    
                    }
                }
            }
        }

        .second-card{
            display: flex;
                flex-direction: row;
                border: 1px solid black;
                margin: 1rem;
                background-color: var(--secondary);
                color: var(--primary);
                .first-block{
                    width: 33.33%;
                    border-right: 1px solid;   
                    padding: 0.25rem;         
                    color: var(--secondary);
                    background-color: var(--primary);
                    .heading{
                        font-size: 0.95rem;
                        font-weight: 600;
                    }    
                }
                .second-block{
                    width: 66.66%;
                    display: flex;
                    flex-direction: column;
                    .h-row{
                        display: flex;
                        flex-direction: row;
                        .details{
                            width: 50%;
                            border-right: 1px solid;                            
                            padding: 0.25rem;
                            span{
                                font-size: 0.75rem;
                                font-weight: 600;
                            }
                            a{
                                color: blue;
                            }                            
                        }
                        .info{
                            width: 50%;
                            font-size: 0.91rem;
                            font-weight: 600;                        
                            padding: 0.25rem;
                        }                        
                    }
                    .first{
                        border-bottom: 1px solid;
                    }
                    .second{
                        border-bottom: 1px solid;
                    }
                }
        }
    }

    @media(max-width: 500px){
        .contactProviderNiumIndiaMain{
            font-family: 'poppins';
            font-size: 0.82rem;
            // text-align: center;
            
            .first-card{
                display: flex;
                flex-direction: column;
                border: 1px solid black;
                margin: 1rem;
                // padding: 0px 0.5rem;
                background-color: var(--secondary);
                color: var(--primary);
                .first-column{
                    // width: 33.33%;
                    border-bottom: 1px solid;   
                    padding: 0.25rem;      
                    color: var(--secondary);
                    background-color: var(--primary);   
                    .heading{
                        font-size: 0.89rem;
                        font-weight: 600;
                        text-align: center;
                    }    
                }
                .second-column{
                    // width: 33.33%;
                    padding: 0.25rem;
                    .heading{
                        font-size: 0.87rem;
                        font-weight: 600;
                    }  
                    .contact{
                        margin-top: 0.7rem;
                        span{
                            font-size: 0.75rem;
                            font-weight: 600;
                        }
                    }
                    .email{
                        margin-top: 0.7rem;
                        span{
                            font-size: 0.75rem;
                            font-weight: 600;
                        }
                        a{
                            color: blue;
                        }
                    }
                    .app-link{
                        margin-top: 0.7rem;
                        span{
                            font-size: 0.75rem;
                            font-weight: 600;
                        }
                        a{
                            color: blue;
                        }
                    }
                }
                .third-column{
                    // width: 33.33%;
                    border-top: 1px solid; 
                    .first{
                        border-bottom: 1px solid;         
                        padding: 0.25rem;           
                        .heading{
                            font-size: 0.87rem;
                            font-weight: 600;
                        }  
                    }
                    .second{
                        padding: 0.25rem;
                        .heading{
                            font-size: 0.91rem;
                            font-weight: 600;
                        }  
    
                    }
                }
            }
        }

        .second-card{
            display: flex;
            flex-direction: column;
            border: 1px solid black;
            margin: 1rem;
            // padding: 0px 0.5rem;
            background-color: var(--secondary);
            color: var(--primary);
                .first-block{
                    // width: 33.33%;
                    border-bottom: 1px solid;   
                    padding: 0.25rem;         
                    color: var(--secondary);
                    background-color: var(--primary);
                    .heading{
                        font-size: 0.89rem;
                        font-weight: 600;
                        text-align: center;
                    }    
                }
                .second-block{
                    // width: 66.66%;
                    display: flex;
                    flex-direction: column;
                    .h-row{
                        display: flex;
                        flex-direction: column-reverse;
                        .details{
                            // width: 50%;
                            // border-right: 1px solid;                            
                            padding: 0.25rem;
                            span{
                                font-size: 0.75rem;
                                font-weight: 600;
                            }
                            a{
                                color: blue;
                            }                            
                        }
                        .info{
                            // width: 50%;
                            font-size: 0.87rem;
                            font-weight: 600;                        
                            padding: 0.25rem;
                        }                        
                    }
                    .first{
                        border-bottom: 1px solid;
                    }
                    .second{
                        border-bottom: 1px solid;
                    }
                }
        }
    }
.service-provider-wrapper{
    font-family: 'poppins';
    margin: 1rem;
    table{
        margin-top: 0.5rem;
    }
    table, td, tr {
        border: 1px solid #000000;
    }
    tr {
        td {
            width: 33.33%;
            padding: 0.5rem;
            background-color: var(--secondary);
            color: var(--primary);
            vertical-align: text-top;
        }
    }
    tr > :nth-last-child(3) {
        background-color: var(--primary);
        color: var(--secondary);
      }
    a {
        color: blue;
    }
}
    
}