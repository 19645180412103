.coinWizard {
  .BMheader {
    .pageheaderdata {
      padding-bottom: 30px;
    }
    .name {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: #000000;
    }
    .bal {
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.37);
      padding-left: 7px;
    } 
  }
  .annualCost {
    .MuiButtonBase-root.MuiButton-root {
      background: #1A4D2E;
      border-radius: 5px;
      border: 1px solid #1A4D2E;
      padding: 11px 14px;
      .btnText {
        font-family: 'Poppins' !important;
        font-size: 10px !important;
        line-height: 15px !important;
        color: #FFFFFF !important;
        text-transform: initial;
      }
    }
  }
  .addMore {
    .MuiButtonBase-root.MuiButton-root {
      border: 1px solid #1A4D2E;
      border-radius: 5px;
      margin-left: 30px;
      padding: 11px 14px;
      .btnText {
        font-family: 'Poppins' !important;
        font-size: 10px !important;
        line-height: 15px !important;
        color: #1A4D2E !important;
        text-transform: initial;
      }
    }
  }
  .boxOuter {
    padding: 42px 30px;
    .left {
      width: 15%;
      margin-top: 25px;
      position: relative;
      .lineImg {
        position: absolute;
        right: 60px;
        top: 25px;
        height: -webkit-fill-available;
      }
    }
    .right {
      width: 70%;
      .box {
        background: #FFFFFF;
        box-shadow: 6px 6px 20px 6px #F5F7F7;
        border-radius: 10px;
        padding: 16px 27px;
        position: relative;
        margin-top: 25px;
        .iconBox {
          position: absolute;
          left: -70px;
          top: 0;
        }
        .newHire {
          position: absolute;
          right: 0;
          top: 0;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 0.65rem;
          line-height: 12px;
          color: #FFFFFF;
          background: #FF8F6F;
          border-radius: 2.5px 9px 2.5px 2.5px;
          padding: 3px 16px 3px 9px;
        }
        .title {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #06283D;
          padding-bottom: 12px;
        }
        .line {
          border: 0.5px solid rgba(0, 0, 0, 0.37);
        }
        .dateOuter {
          padding: 15px 0px;
          .subTitle {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.65rem;
            line-height: 14px;
            color: rgba(0, 0, 0, 0.37);
            width: 20px;
          }
          .date {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 0.65rem;
            line-height: 14px;
            color: rgba(0, 0, 0, 0.37);
          }
        }
        .coinData {
          padding: 10px 0;
          .coinCount {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #06283D;
            width: 50%;
          }
          .editEnroll {
            width: 50%;
            .enroll {
              .MuiButtonBase-root.MuiButton-root {
                background: #1A4D2E;
                border-radius: 5px;
                margin: 0;
                .btnText {
                  font-family: 'Poppins' !important;
                  font-style: normal !important;
                  font-weight: 400 !important;
                  font-size: 10px !important;
                  line-height: 15px !important;
                  color: #FFFFFF !important;
                  text-transform: initial;
                }
              }
            }
            .edit {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 10px;
              line-height: 15px;
              color: #FF8F6F;
              .editArrow {
                padding-left: 6px;
              }
            }
          }
        }
      }
      .box.active {
        background: #1A4D2E;
        .title {
          color: #FFFFFF;
        }
        .line {
          border-color: #fff;
        }
        .dateOuter {
          .subTitle {
            color: #fff;
          }
          .date {
            color: #fff;
          }
        }
        .coinData {
          .coinCount {
            color: #fff;
          }
        }
        .iconBox {
          left: -75px;
        }
      }
    }
  }
  .finalEnroll {
    padding: 0 30px;
    margin-top: 50px;
    .MuiButtonBase-root.MuiButton-root {
      background: #FFD24C;
      border-radius: 5px;
      .btnText {
        font-family: 'Poppins' !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #1A4D2E !important;
      }
    }
  }
}

.none {
  display: none;
}
@media (max-width: 767px) {
  .coinWizard {
    .annualCost {
      width: 50%;
    }
    .addMore {
      width: 50%;
    }
    .boxOuter {
      .left {
        .lineImg {
          right: 40px;
        }
      }
      .right {
        width: 90%;
        .box {
          .iconBox {
            left: -50px;
          }
        }
        .box.active {
          .iconBox {
            left: -53px;
          }
        }
      }
    }
  }
}





.coinWizard2 {
  .boxOuter {
    .AddBeneficiaryMainPage {
      .btnOuter {
        border-radius: 5px;
        .active {
          background: #1A4D2E;
          border-radius: 5px;
        }
      }
    }
  }
  .heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #06283D;
    margin-bottom: 27px;
  }
  .moreDetails {
    width: fit-content;
    .MuiButtonBase-root.MuiButton-root {
      background: #1A4D2E;
      border-radius: 5px;
      .btnText {
        color: #fff !important;
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 10px !important;
        line-height: 15px !important;
        text-transform: initial;
      }
    }
  }
  .box {
    background: #F6FBF4;
    border-radius: 5px;
    padding: 17px 26px;
    margin-bottom: 20px;
    .title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #06283D;
    }
    .subTitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.37);
      padding-top: 6px;
    }
  }
  .head {
    width: 50%;
    .label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.51);
    }
  } 
  .head.first {
    padding-left: 20px;
  }
  .box1 {
    margin-top: 18px;
    padding: 20px;
    background: #F5F7F7;
    border-radius: 5px;
    .data1 {
      width: 50%;
      position: relative;
      .iconActive {
        position: absolute;
        left: 0;
      }
      .coverageAmount {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #06283D;
        width: 50%;
        padding-left: 30px;
      }
      .imgOuter {
        width: 50%;
        .arrowImg {
          height: fit-content;
        }
      }
    }
    .data2 {
      width: 50%;
      .EmployeeCost {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #06283D;
      }
      .selectBtn {
        .MuiButtonBase-root.MuiButton-root {
          background: #1A4D2E;
          border-radius: 5px;
          margin: auto;
          padding: 0px 30px;
          .btnText {
            font-family: 'Poppins' !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 12px !important;
            line-height: 18px !important;
            color: #FFFFFF !important;
          }
        }
      }
    }
  }
  .box1.active {
    background: #1A4D2E;
    .coverageAmount {
      color: #FFFFFF;
    }
    .EmployeeCost {
      color: #FFFFFF;
    }
    .data2 {
      .selectBtn {
        .MuiButtonBase-root.MuiButton-root {
          background: #FFD24C;
          .btnText {
            color: #1A4D2E !important;
          }
        }
      }
    }
    .arrowImg {
      display: none;
    }
    .arrowImg.active {
      display: block;
    }
  }
  .submit {
    padding: 30px;
    .MuiButtonBase-root.MuiButton-root {
      // background: #FFD24C;
      border-radius: 5px;
    }
  }

}



@media (max-width: 767px) {
  .coinWizard2 {
    .box1 {
      padding: 15px;
      .data1 {
        .imgOuter {
          .arrowImg {
            margin-left: 20px;
            width: 30px;
          }
        }
      }
      .data2 {
        .selectBtn {
          .MuiButtonBase-root.MuiButton-root {
            padding: 0px 15px;
          }
        }
      }
    }
  }
}
.CoverageDetailsPopup{
  .coinPopTable{
    .MuiTableContainer-root{
      box-shadow: none;
    }
  table{
    th{
      border: none !important;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 0.95rem;
      line-height: 18px;
      color: #06283D;
      padding-bottom: 12px;
    }
    td{
      border: none !important;
    }
    border: none !important;
    
    tr{
      border-bottom: 1px solid gray;
      td{
     font-family: "Poppins";
     font-style: normal;
     font-weight: 500;
     font-size: 0.7rem;
     line-height: 18px;
     padding-bottom: 12px;
     color: #06283D;
     }
    }
    .lightcolorfont{
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 18px;
      padding-bottom: 12px;
      color: rgba(0, 0, 0, 0.37);
    }
  }
}
}
