.reim-enroll-main-page{
    .main-reim-en{
        padding: 1rem;
    }    
    .radioButtonOuter {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .radioButtonOuter .radioButton {
        display: inline-flex;
        background: var(--tertiary);
        padding: 15px 15px;
        margin: 0.5rem 0;
        font-style: normal;
        font-weight: 400;
        font-size: 0.95rem;
        line-height: 15px;
        color: var(--primary);
        align-items: center;
        width: 48%;
        // background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
        background-size: 200%;
        transition: 0.25s ease-out;
        background-position: right;
      }
      .radioButtonOuter .radioButton .radioOuter {
        position: relative;
        margin-right: 5px;
        min-width: 1.7rem;
      }
      .radioButtonOuter .radioOuter .radioCheck {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 7px;
        opacity: 0;
      }
    
      .radioButtonOuter .radioButton.active .radioCheck {
        opacity: 1;
      }
      .radioButtonOuter .radioOuter svg {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 4px;
        opacity: 1;
      }
    
      .radioButtonOuter .radioButton.active {
        background: var(--primary);
        color: #fff;
        background: linear-gradient(to left, var(--tertiary) 50%, var(--primary) 50%) right;
        background-size: 200%;
        transition: 0.25s ease-out;
        background-position: left;
      }
}