.claimHistory{
    .mainTitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 24px;
        color: var(--primary);
      }
      .cards {
        margin-top: 20px;
        .cardsOuterOuter{
          display: flex;
          padding-bottom: 20px;
          .cardsOuter {            
            width: 100%;
            display: flex;
            justify-content: space-between;
            background: #FAFAFA;
            padding: 15px;
            // margin-bottom: 20px;
            // cursor: pointer;
            .left {
              .name {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                line-height: 18px;
                color: var(--primary);
                padding-bottom: 5px;
              }
              .date {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 0.9rem;
                line-height: 12px;
                color: rgba(0, 0, 0, 0.37);
              }
            }
            .right {
              display: flex;
              align-items: center;
              position: relative;
              // padding-right: 20px;
              .statusOuter {
                .status {
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 0.8rem;
                  line-height: 12px;
                  color: rgba(0, 0, 0, 0.37);
                  margin-bottom: 5px;
                }
                .statusInner {
                  display: flex;
                  align-items: center;
                  position: relative;
                  .circle {
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 50px;
                    position: absolute;
                    left: -15px;
                  }
                  .statusDetails {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 0.9rem;
                    line-height: 15px;
                  }
                  .circle.pending {
                    background: red;
                  }
                  .statusDetails.pending {
                    color: #E90F0F;
                  }
                  .circle.paid {
                    background: var(--primary);
                  }
                  .statusDetails.paid {
                    color: var(--primary);
                  }
                  
      
                }
              }
              .arrowRight {
                position: absolute;
                right: 0;
              }
              .claimBtns{
                display: flex;
                flex-direction: row;
                margin-right: 2rem;
                @media(max-width:500px){
                  flex-direction: column;
                }
                .deleteIconEvent{
                // width: 10%;
                display: flex;
                justify-content: center;
                align-items: center;
                button{
                  background-color: var(--secondary);
                  color: var(--primary);
                  padding: 2px 5px;
                  border-radius: 1rem;
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 0.75rem;
                  text-transform: capitalize;
                }
            }
              .viewEvent{
                // width: 10%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.5rem;
                @media(max-width:500px){
                  margin-right: 0rem;
                  margin-bottom: 0.5rem;
                }
                button{
                  background-color: var(--primary);
                  color: var(--secondary);
                  padding: 2px 5px;
                  border-radius: 1rem;
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 0.75rem;
                  text-transform: capitalize;
                }
            }
              }
            }
          }
                  
       
        }
        
      }

      .reimbursementModal {
        hr {
          margin: 20px 0px;
        }
        .mainTitle {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
        .statusOuter {
          text-align: center;
          .status {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 12px;
            color: rgba(0, 0, 0, 0.37);
            margin-bottom: 5px;
          }
          .statusInner {
            display: flex;
            align-items: center;
            position: relative;
            width: fit-content;
            margin: 0 auto;
            .circle {
              width: 7px;
              height: 7px;
              border-radius: 50px;
              position: absolute;
              left: -15px;
            }
            .statusDetails {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 10px;
              line-height: 15px;
            }
            .circle.pending {
              background: red;
            }
            .statusDetails.pending {
              color: #E90F0F;
            }
            .circle.paid {
              background: var(--primary);
            }
            .statusDetails.paid {
              color: var(--primary);
            }
          }
      
        }
        .dataOuter {
          display: flex;
          justify-content: space-between;
        }
        .receiptsImages{
          margin-top: 0.5rem;
          @media(max-width:500px){
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 10px;
          }
          @media(min-width:500px){
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              grid-gap: 10px;
          }
        }
        .claimDetails {
          background: #FAFAFA;
          padding: 15px;
          margin-top: 20px;
          .claimDetailsInner {
            .claimFor {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 0.9rem;
              line-height: 18px;
              color: var(--primary);
            }
            .claimPerson {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 1rem;
              line-height: 24px;
              color: rgba(0, 0, 0, 0.37);
            }
            .amount {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 0.7rem;
              line-height: 15px;
              color: rgba(0, 0, 0, 0.37);
            }
            .price {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 2rem;
              line-height: 36px;
              color: rgba(0, 0, 0, 0.37);
            }
          }
        }
      
      }
    
      
}

.claimHistoryD{
  .mainTitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 24px;
      color: #1A4D2E;
    }
    .cards {
      padding-top: 20px;
      .cardsOuter {
        display: flex;
        justify-content: space-between;
        background: #FAFAFA;
        padding: 15px;
        margin-bottom: 20px;
        cursor: pointer;
        .left {
          .name {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 18px;
            color: #1A4D2E;
            padding-bottom: 5px;
          }
          .date {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 12px;
            color: rgba(0, 0, 0, 0.37);
          }
        }
        .right {
          display: flex;
          align-items: center;
          position: relative;
          padding-right: 20px;
          .statusOuter {
            .status {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 0.9rem;
              line-height: 12px;
              color: rgba(0, 0, 0, 0.37);
              margin-bottom: 5px;
            }
            .statusInner {
              display: flex;
              align-items: center;
              position: relative;
              .circle {
                width: 10px;
                height: 10px;
                border-radius: 50px;
                position: absolute;
                left: -15px;
              }
              .statusDetails {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 1rem;
                line-height: 15px;
              }
              .circle.pending {
                background: red;
              }
              .statusDetails.pending {
                color: #E90F0F;
              }
              .circle.paid {
                background: #1A4D2E;
              }
              .statusDetails.paid {
                color: #1A4D2E;
              }
              
  
            }
          }
          .arrowRight {
            position: absolute;
            right: 0;
          }
        }
      }
    }

    .reimbursementModal {
      hr {
        margin: 20px 0px;
      }
      .mainTitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      .statusOuter {
        text-align: center;
        .status {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 12px;
          color: rgba(0, 0, 0, 0.37);
          margin-bottom: 5px;
        }
        .statusInner {
          display: flex;
          align-items: center;
          position: relative;
          width: fit-content;
          margin: 0 auto;
          .circle {
            width: 7px;
            height: 7px;
            border-radius: 50px;
            position: absolute;
            left: -15px;
          }
          .statusDetails {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 15px;
          }
          .circle.pending {
            background: red;
          }
          .statusDetails.pending {
            color: #E90F0F;
          }
          .circle.paid {
            background: #1A4D2E;
          }
          .statusDetails.paid {
            color: #1A4D2E;
          }
        }
    
      }
      .dataOuter {
        display: flex;
        justify-content: space-between;
      }
      .claimDetails {
        background: #FAFAFA;
        padding: 15px;
        margin-top: 20px;
        .claimDetailsInner {
          .claimFor {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 0.9rem;
            line-height: 18px;
            color: #1A4D2E;
          }
          .claimPerson {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.37);
          }
          .amount {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.7rem;
            line-height: 15px;
            color: rgba(0, 0, 0, 0.37);
          }
          .price {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 2rem;
            line-height: 36px;
            color: rgba(0, 0, 0, 0.37);
          }
        }
      }
    
    }
  
 
}