.chooseCompanyPage {
  .data {
    height: 100%;
    .PageTitle-And-Description {
      padding: 0px 10px;
    }
    .bottomButtonReq {
      padding: 0 10px;
    }
    
  .radioButtonOuter {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 1rem;
  }
  .radioButtonOuter .radioButton {
    display: inline-flex;
    background: var(--tertiary);
    padding: 15px 10px;
    margin: 0.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 18px;
    color: var(--primary);
    align-items: center;
    width: 100%;
  }
  .radioButtonOuter .radioButton .radioOuter {
    position: relative;
    margin-right: 5px;
    min-width: 1.7rem;
  }
  .radioButtonOuter .radioOuter .radioCheck {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 7px;
    opacity: 0;
  }

  .radioButtonOuter .radioButton.active .radioCheck {
    opacity: 1;
  }
  .radioButtonOuter .radioButton.active {
    background: var(--primary);
    color: #fff;
  }

}
}
