.profilePic{
    margin: 0px auto;
    position: relative;
    img{        
        border: 2px solid  var(--primary);
        border-radius: 5rem;
        width: 120px;
        height: 120px;
        margin: 0px auto;
    }

    .profile-pic-upload-err-circle{
        border: 2px solid  var(--primary);
        border-radius: 5rem;
        width: 120px;
        height: 120px;
        margin: 0px auto;
        text-align: center;
        padding-top: 2.5rem;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 20px;
        color: var(--warning);
    }

    .uploadImg{
        cursor: pointer;
        position: absolute;
        right: 0%;
        bottom: 9%;
        background:  var(--primary);
        color: #ffffff;
        padding: 5px;
        border-radius: 16px;
        svg{
            cursor: pointer;
        }

    }
}
.profile-pic-upload-err{
    width: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 20px;
    color: var(--warning);
    text-align: center;
  }
.bankEditBTN{
    background-color: #1976d2;
    color: #ffffff;
    padding: 0.25rem 1rem;
    height: 2rem;
    border-radius: 2rem;
    cursor: pointer;
    &:hover{
        background-color: #318be6;
    }
}