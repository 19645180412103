.bundle-rate-card-popup {
    .upper-heading{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 18px;
        color: var(--primary);
        margin-bottom: 0.5rem;
    }
  .rate-cards-main {
    margin: 0.75rem 0px;
    padding: 0.75rem;
    .one-parent {
        h2{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 1.1rem;
            line-height: 24px;
            color: var(--primary);
        }
        p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.75re,;
            line-height: 14px;
            color: var(--primary);
            opacity: 0.8;
            margin: 0.5rem 0px;
        }
    }
    .rates-table {
        width: 100%;
        text-align: left;
        .header-row{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 0.75rem;
            line-height: 14px;
            color: var(--primary);

            .rate-col{
                width: 30%;
            }
            .rate-pre{
                width: 30%;
            }
            tr{
                th{
                   padding: 0.32rem 0px; 
                }                
            }
            // display: flex;
            // justify-content: space-between;
        }
        .row{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 0.79rem;
            line-height: 16px;
            border-top: 1px solid var(--primary);
            color: var(--primary);

            tr{
                td{
                    padding: 0.32rem 0px; 
                 }
            }

            // display: flex;
            // justify-content: space-between;
        }
    }
  }
  .one{
    background-color: #FAF3E3;
  }
  .two{
    background-color: #FFE6E6;
  }
  .three{
    background-color: #EBF0F9;
  }
  .four{
    // background-color: var(--primary);
    background-color: #d9d7ee;
    .one-parent {
        h2{
            color: var(--primary);
        }
        p{
            color: var(--primary);
        }
        .rates-table {
            .header-row{
                color: var(--primary);
            }
            .row{
                color: var(--primary);
                border-top: 1px solid var(--primary);
            }
        }
    }
  }
}
