.coverageTypePage{
    .data {
        padding: 30px 1rem;
        .PageTitle-And-Description {
            margin-left: 0;
            margin-top: 0;
        }
        .bottomButtonReq {
            padding: 0;
            padding-top: 1rem;
        }        
    }
    .data .btnOuter {
        padding: 10px 10px;
        // background: var(--tertiary);
        background: #e5e5e5;
    }
    .data .btnOuter .btn {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 21px;
        color: var(--primary);
        text-align: center;
        width: 50%;
        padding: 15px 0px;
        text-transform: capitalize;
    }
    .data .btnOuter .btn.active {
        background: var(--primary);
        color: #fff;
    }

    .data .group {
        padding: 0 10px;
    }
    .data .group .text {
        font-weight: 400;
        font-size: 0.6875rem;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.51);
    }



    // .data .card {
    //     padding: 15px 20px;
    //     background: var(--tertiary);
        
    //     // &:hover{           
    //     //     .price{
    //     //         font-size: 1rem;
    //     //         transition: font-size ease-in-out 250ms;
    //     //     }
    //     // }
        
    // }
    .data .card .cardInner {
        // border-bottom: 0.4px solid rgba(0, 0, 0, 0.37);
        // padding-bottom: 10px;
    }
    .data .card .title {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.51);
        padding-bottom: 10px;
    }
    .data .card .price {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 18px;
        color: var(--primary);
        transition: font-size ease-in-out 250ms;
    }
    
    .data .card .cardInner2 .coverText {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.51);
    }
    .data .card .cardInner2 .btn.active {
        background: var(--primary);
        color: #fff;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 18px;
        padding: 5px 27px;
        cursor: pointer;
        &:hover{
            padding: 4px 26px;
            font-weight: 600;
            background: var(--secondary);
            border: 1px solid var(--primary);
            color: var(--primary);
        }
    }
    .activeCard{
     border: 2px solid var(--primary);
     .cardInner{
        .left{
           .price{
        font-size: 1rem;
        transition: font-size ease-in-out 250ms;
    } 
        }
     }
     .cardInner2{
        .left{
           .price{
        font-size: 1rem;
        transition: font-size ease-in-out 250ms;
    } 
        }
     }
     
    }
    
}

.singleDependant{
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 21px;
        color: var(--primary);
    .card {
        padding: 15px 20px;
        background: var(--tertiary);
        
        // &:hover{           
        //     .price{
        //         font-size: 1rem;
        //         transition: font-size ease-in-out 250ms;
        //     }
        // }
        
    }
    .card .cardInner {
        // border-bottom: 0.4px solid rgba(0, 0, 0, 0.37);
        // padding-bottom: 10px;
    }
    .card .title {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.51);
        padding-bottom: 10px;
    }
    .card .price {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 18px;
        color: var(--primary);
        transition: font-size ease-in-out 250ms;
    }
    
    .card .cardInner2 .coverText {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.51);
    }
    .card .cardInner2 .btn.active {
        background: var(--primary);
        color: #fff;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 18px;
        padding: 5px 27px;
        cursor: pointer;
        &:hover{
            padding: 4px 26px;
            font-weight: 600;
            background: var(--secondary);
            border: 1px solid var(--primary);
            color: var(--primary);
        }
    }
    .activeCard{
     border: 2px solid var(--primary);
     .cardInner{
        .left{
           .price{
        font-size: 0.82rem;
        transition: font-size ease-in-out 250ms;
    } 
        }
     }
     .cardInner2{
        .left{
           .price{
        font-size: 0.82rem;
        transition: font-size ease-in-out 250ms;
    } 
        }
     }
    }

      .coverageSelectHead{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
          padding: 5px 10px;
          // background-color: var(--secondary);
          color: var(--primary);
          font-weight: 600;
          font-size: 0.95rem;
          line-height: 18px;
      }
      .coverageSelectHead1{
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
          padding: 5px 10px;
          // background-color: var(--secondary);
          color: var(--primary);
          font-weight: 600;
          font-size: 0.95rem;
          line-height: 18px;
      }
      .costsHead{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 18px;
        /* identical to box height */
        
        
        color: rgba(0, 0, 0, 0.51);
        
      }
      .cCount{
        width: 35%;
      }
      .parentBundle{
        width: 25%;
      }
      .pilBundle{
        width: 25%;
      }
      .blank{
        @media(min-width: 500px){
          width: 12rem;
        }
      }
      .coverageSelectionPageContent{
        font-family: 'poppins';
        font-weight: 500;
        font-size: 0.81rem;
        color: rgba(0, 0, 0, 0.37);
        margin: 0px 1rem;
        padding-top: 1rem;
        margin-bottom: -1rem;
      }
}

.singleDependantIndividual{
    .card {
        padding: 5px 20px;
        background: #e0e0e0;
        
        // &:hover{           
        //     .price{
        //         font-size: 1rem;
        //         transition: font-size ease-in-out 250ms;
        //     }
        // }
        
    }
    .card .cardInner {
        // border-bottom: 0.4px solid rgba(0, 0, 0, 0.37);
        // padding-bottom: 10px;
    }
    .card .title {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.51);
        padding-bottom: 10px;
    }
    .card .price {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 18px;
        color: var(--primary);
        transition: font-size ease-in-out 250ms;
    }
    
    .card .cardInner2 .coverText {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.51);
    }
    .card .cardInner2 .btn.active {
        background: var(--primary);
        color: #fff;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 18px;
        padding: 5px 27px;
        cursor: pointer;
        &:hover{
            padding: 4px 26px;
            font-weight: 600;
            background: var(--secondary);
            border: 1px solid var(--primary);
            color: var(--primary);
        }
    }
    .activeCard{
     border: 2px solid var(--primary);
     .cardInner{
        .left{
           .price{
        font-size: 0.82rem;
        transition: font-size ease-in-out 250ms;
    } 
        }
     }
     .cardInner2{
        .left{
           .price{
        font-size: 0.82rem;
        transition: font-size ease-in-out 250ms;
    } 
        }
     }
    }
     .mainCoverageSelection{
        // padding: 0.5rem 0.5rem;
        background-color: var(--tertiary);
        // border: 2px solid #f1f1f1;
        margin: 1rem 0px;
        .coverageSelectHead{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
            padding: 5px 10px;
            // background-color: var(--secondary);
            color: var(--primary);
            font-weight: 600;
            font-size: 0.95rem;
            line-height: 18px;
        }
        .coverageSelectHead1{
          display: flex;
          flex-direction: row;
          // justify-content: space-between;
            padding: 5px 10px;
            // background-color: var(--secondary);
            color: var(--primary);
            font-weight: 600;
            font-size: 0.95rem;
            line-height: 18px;
        }
        .costsHead{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 0.9rem;
          line-height: 18px;
          /* identical to box height */
          
          
          color: rgba(0, 0, 0, 0.51);
          
        }
     }
    
 
}
.MuiAccordionDetails-root{
  background-color: var(--tertiary);
}
.coverageSelectButtonActive{
  background: var(--primary);
  color: var(--secondary);
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 18px;
  padding: 5px 27px;
  cursor: pointer;
}
.coverageSelectButton{
  background: var(--secondary);
  color: var(--primary);
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 18px;
  padding: 5px 27px;
  cursor: pointer;
}

@keyframes slideIn {
  0% {
    transform: translateY(400px);
    animation-timing-function: ease-out;
  }
  // 60% {
  //   transform: translateY(-30px);
  //   animation-timing-function: ease-in;
  // }
  // 80% {
  //   transform: translateY(10px);
  //   animation-timing-function: ease-out;
  // }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}  


.totalCostPopup {
  animation: slideIn 1s linear;
  background: #fff;
  // position: fixed;
  bottom: 0px;
  z-index: 1;
  border: 1px solid green;
  border-radius: 33px 33px 0px 0px;
  padding: 15px 15px;
  // box-shadow: 1rem 15rem 1rem 44rem rgb(0 0 0 / 25%);
  
  @media (min-width: 768px) {
    // bottom: 27%;
    // left: 30%;
    width: 768px;
    border-radius: 33px 33px 33px 33px;
    padding: 2rem;
    // animation: zoomout 1s;
  }
  
  @media (max-width: 768px) {
    position: fixed;
    width: 100%;
    table{
      width: 100%;
    }
  }
}
.totalCostPopup .lineGreen {
  margin: 0 auto;
  padding: 15px 0px;
}
.totalCostPopup .title {
  font-weight: 600;
  font-size: 1rem;
  color: var(--primary);
  padding-top: 20px;
  padding-bottom: 15px;
}
.totalCostPopup table thead {
  border-top: 0.7px solid rgba(0, 0, 0, 0.23);
  border-bottom: 0.7px solid rgba(0, 0, 0, 0.23);
}
.totalCostPopup table thead tr th {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.37);
  text-align: right;
}
.totalCostPopup table thead tr th:nth-of-type(1) {
  text-align: left;
}
.totalCostPopup table tbody tr td {
  padding: 10px 0;
}
.totalCostPopup table tbody tr:nth-last-child(1) {
  border-top: 0.7px solid rgba(0, 0, 0, 0.23);
  border-bottom: 0.7px solid rgba(0, 0, 0, 0.23);
}
.totalCostPopup table tbody tr td {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 18px;
  color: var(--primary);
  text-align: right;
}
.totalCostPopup table tbody tr td:nth-of-type(1) {
  text-align: left;
}
.totalCostPopup table tbody tr:nth-last-child(1) td {
  font-weight: 600;
  font-size: 1rem;
  line-height: 30px;
  color: var(--primary);
}
.totalCostPopup .total {
  padding-top: 50px;
}
.totalCostPopup .total .totalOuter .totalText {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.37);
}
.totalCostPopup .total .totalOuter .include {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.65);
  padding-top: 5px;
  font-style: italic;
}
.totalCostPopup .total .totalOuter .include .total {
  font-weight: 600;
  font-size: 1rem;
  line-height: 30px;
  color: var(--primary);
  // padding-right: 10px;
}
.totalCostPopup .total .backBtn {
  background: var(--secondary);
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  color: var(--primary);
  text-transform: uppercase;
  width: 50%;
  padding: 10px;
}
@media (min-width: 768px) {
  .popupWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}    

