
.reportAEventPage{
    .reportNote{
        text-align: end;
        margin: 0px 1rem;
        color: gray;
        font-style: italic;
        font-size: 1rem;
    }
    .eventList{

        .accorEventName{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 24px;
            /* identical to box height */
            color: var(--primary);
        }

    }
    .font_bold {
        font-weight: 600;
    }

    .radio_btn {
    margin: .4rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 45px;
        background: #FAFAFA;
        color: grey;
        font-size: 1.6rem;
        padding: 1rem;
        font-family: "Poppins", sans-serif;
    }

    
    .radio_btn:hover{
        background: #f1eaea;
        cursor: pointer;
        
    }

    @media (max-width:780px) {
        .radio_btn{
            font-size: 1rem;
        }
    }
}